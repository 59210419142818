import {MeetingRoomSVGData, MeetingRoomSVGUpdateData} from "../../../Utils/svgPlans/meetingRooms";
import {MeetingRoom} from "../../../API";
import {useMemo} from "react";

const NO_MEET_ROOM_SVG_UPDATE_DATA: MeetingRoomSVGUpdateData[] = [];

export function useJoinedDBAndSVGMeetingRoomData(meetingRooms: MeetingRoom[], meetingRoomSVGData: MeetingRoomSVGData[]): MeetingRoomSVGUpdateData[] {
    return useMemo(() => {
        if (meetingRooms.length === 0 || meetingRoomSVGData.length === 0) {
            return NO_MEET_ROOM_SVG_UPDATE_DATA;
        }
        const idToMeetingRoom = new Map(meetingRooms.map(mr => [mr.meetingRoomId, mr]));
        const result: MeetingRoomSVGUpdateData[] = [];
        meetingRoomSVGData.forEach((svgMRData) => {
            const id = svgMRData.meetingRoomId;
            const dbMeetingRoom = idToMeetingRoom.get(id);
            if (dbMeetingRoom === undefined) {
                return; // no name or capacity in DB
            }
            let capacity = dbMeetingRoom.capacity;
            if (capacity === null || capacity === undefined) {
                capacity = svgMRData.roomCap;
            }
            result.push(
                {
                    meetingRoomId: id,
                    meetingRoomName: dbMeetingRoom.name,
                    capacity: capacity
                }
            );
        });
        return result;
    }, [meetingRooms, meetingRoomSVGData]);
}
