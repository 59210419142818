import {gql, useQuery} from "@apollo/client";
import {getRooms} from "../graphql/queries";
import {useEffect} from "react";
import {Room} from "../API"
import {onChangeRoomList} from "../graphql/subscriptions";


export function useRoomList(): { rooms: Room[], roomsLoading: boolean } {

    // Lambda is used for getRooms Query
    const {data, subscribeToMore, refetch, loading: roomsLoading} = useQuery(gql(getRooms), {
        variables: {
            limit: 999
        },
    });
    const roomCompareByName = (a: Room, b: Room) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1

    useEffect(() => {

        const unsubscribeOnRoomListChange = subscribeToMore({
            document: gql(onChangeRoomList),
            updateQuery: (prev) => {
                refetch().then()
                return prev
            },
            onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
        });

        return () => unsubscribeOnRoomListChange();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rooms = data?.getRooms?.items.sort(roomCompareByName) ?? [];

    return {rooms, roomsLoading};
}
