import {useMainApplicationContext} from "../useMainApplicationContext";
import {useMemo} from "react";

export function useIsUserOrgUnitAdminOfRoom(roomId: string) {
    const {currentUser, rooms} = useMainApplicationContext();

    const isUserOrgUnitAdminOfRoom = useMemo(() => {
        if (!roomId) {
            return false;
        }
        const room = rooms.find(room => room.roomId === roomId);
        if (!room?.orgUnitAdmin) {
            return false;
        }
        const administratedOrgUnitsIds = currentUser?.adminOrgUnits?.map(orgUnit => orgUnit.orgId) ?? [];
        return administratedOrgUnitsIds.includes(room.orgUnitAdmin);
    }, [roomId, rooms, currentUser]);

    return {isUserOrgUnitAdminOfRoom};
}