import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import CloseButton from "../Buttons/CloseButton";

interface SvgDuplicateErrorDialogProps {
    open: boolean;
    onClose: () => void;
    duplicates: {
        seats: string[];
        neighborhoods: string[];
        meetingRooms: string[];
        hints: string[];
    };
}

const SvgDuplicateErrorDialogComponent: React.FC<SvgDuplicateErrorDialogProps> = (props) => {
    const {open, onClose, duplicates} = props;
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("duplicate_error_dialog_title")}</DialogTitle>
            <DialogContent>
                <Typography style={{whiteSpace: "pre-line"}}>
                    {t("duplicate_error_dialog_text")}
                </Typography>
                {duplicates.seats.length > 0 && (
                    <>
                        <Typography variant="subtitle1" style={{marginTop: 16, marginBottom: 8}}>
                            {t("duplicate_error_dialog_seats")}
                        </Typography>
                        <List dense>
                            {duplicates.seats.map((seat, index) => (
                                <ListItem key={index} data-testid={`duplicate-seat-${index}`}>
                                    <ListItemText secondary={seat}/>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {duplicates.neighborhoods.length > 0 && (
                    <>
                        <Divider style={{marginTop: 16, marginBottom: 8}}/>
                        <Typography variant="subtitle1" style={{marginTop: 16, marginBottom: 8}}>
                            {t("duplicate_error_dialog_neighborhoods")}
                        </Typography>
                        <List dense>
                            {duplicates.neighborhoods.map((neighborhood, index) => (
                                <ListItem key={index} data-testid={`duplicate-neighborhood-${index}`}>
                                    <ListItemText secondary={neighborhood}/>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {duplicates.meetingRooms.length > 0 && (
                    <>
                        <Divider style={{marginTop: 16, marginBottom: 8}}/>
                        <Typography variant="subtitle1" style={{marginTop: 16, marginBottom: 8}}>
                            {t("duplicate_error_dialog_meeting_rooms")}
                        </Typography>
                        <List dense>
                            {duplicates.meetingRooms.map((meetingRoom, index) => (
                                <ListItem key={index} data-testid={`duplicate-meeting-room-${index}`}>
                                    <ListItemText secondary={meetingRoom}/>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {duplicates.hints.length > 0 && (
                    <>
                        <Divider style={{marginTop: 16, marginBottom: 8}}/>
                        <Typography variant="subtitle1" style={{marginTop: 16, marginBottom: 8}}>
                            {t("duplicate_error_dialog_hints")}
                        </Typography>
                        <List dense>
                            {duplicates.hints.map((hint, index) => (
                                <ListItem key={index} data-testid={`duplicate-hint-${index}`}>
                                    <ListItemText secondary={hint}/>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={onClose} data-testid="close-btn"/>
            </DialogActions>
        </Dialog>
    );
};

export default SvgDuplicateErrorDialogComponent;