import {Dialog, DialogActions, DialogContent, DialogTitle, Typography,} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import CloseButton from "../Buttons/CloseButton";

interface SvgValidationErrorDialogProps {
    open: boolean;
    onClose: () => void;
    error: {
        code: string;
        msg: string;
        line: number;
        col: number;
    } | null;
}

const SvgValidationErrorDialogComponent: React.FC<SvgValidationErrorDialogProps> = (props) => {
    const {open, onClose, error} = props;
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("svg_validation_error_dialog_title")}</DialogTitle>
            <DialogContent>
                <Typography style={{whiteSpace: "pre-line"}}>
                    {t("svg_validation_error_dialog_text")}
                </Typography>
                {error && (
                    <div style={{marginTop: 16}}>
                        <Typography variant="body2" data-testid="error-code">
                            {t("svg_validation_error_dialog_code")}: {error.code}
                        </Typography>
                        <Typography variant="body2" data-testid="error-message">
                            {t("svg_validation_error_dialog_message")}: {error.msg}
                        </Typography>
                        <Typography variant="body2" data-testid="error-line">
                            {t("svg_validation_error_dialog_line")}: {error.line}
                        </Typography>
                        <Typography variant="body2" data-testid="error-column">
                            {t("svg_validation_error_dialog_column")}: {error.col}
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <CloseButton onClick={onClose} data-testid="close-btn"/>
            </DialogActions>
        </Dialog>
    );
};

export default SvgValidationErrorDialogComponent;