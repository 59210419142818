import {SeatInterface} from "../SeatComponent";
import {BookingP} from "../../../types/PermissionHandling";
import {useMemo} from "react";

export function useSeatOverlayData(roomId: string, seatSVGData: SeatInterface[], bookings: BookingP[]) {
    return useMemo(() => {
        return seatSVGData.map((seat) => {
            let bookingsOfSeat = bookings
                .filter(bi => bi.seatId === seat.seatID
                    && bi.roomId === roomId)
            return {
                seat: seat,
                bookings: bookingsOfSeat,
            }
        })
    }, [bookings, roomId, seatSVGData]);
}