import {useEffect, useState} from 'react';
import { useMeetingRoomSeatConfigurations} from "./useMeetingRoomSeatConfigurations";
import {MeetingRoomBookingListItem, OwnMeetingRoomBooking} from '../types/MeetingRoomBookingListItemType';

const useSeatConfigs = (bookings: OwnMeetingRoomBooking[] | MeetingRoomBookingListItem[]) => {
    const [seatConfigs, setSeatConfigs] = useState<string[]>([]);

    useEffect(() => {
        let tmpConfigMap: Map<string, string[]> = new Map<string, string[]>();
        bookings.forEach(item => {
            if (!tmpConfigMap.has(item.roomId)) {
                tmpConfigMap.set(item.roomId, []);
            }

            tmpConfigMap.get(item.roomId)?.push(item.meetingRoomId)
        })

    }, [bookings]);

    return seatConfigs
};

export default useSeatConfigs;
