import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import Paper from "@mui/material/Paper";
import {useRoomPlanContext} from "../../../hooks/useRoomPlanContext";
import dayjs from "dayjs";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import GroupsIcon from '@mui/icons-material/Groups';
import {getMeetingTypeLabel} from "../../../Utils/Enums";
import {t} from "i18next";

interface Props {
    mousePosX: number,
    mousePosY: number,
    offset: number,
    currentUserID: string,
    styleCell: CSSProperties
}

const BookingMeetingRoomList: React.FC<Props> = (props) => {

    const {
        mousePosX,
        mousePosY,
        styleCell,
        offset
    } = props

    const {
        bookingsToDisplay
    } = useRoomPlanContext()

    const [elementPositionX, setElementPositionX] = useState(mousePosX);
    const [elementPositionY, setElementPositionY] = useState(mousePosY);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (elementRef.current) {
            if (window.innerWidth - offset < (mousePosX + elementRef.current.offsetWidth)) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset)
            }
            if (window.innerHeight - offset < (mousePosY + elementRef.current.offsetHeight)) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Paper ref={elementRef} elevation={3} sx={{
            overflowWrap: 'anywhere',
            position: "absolute",
            left: elementPositionX,
            top: elementPositionY,
            backgroundColor: "white",
            pointerEvents: "none",
            whiteSpace: "nowrap"
        }}>
            <Table stickyHeader>
                <TableBody data-testid={"inventory-list"}>
                    {bookingsToDisplay.length > 0 && bookingsToDisplay.map((bookingToDisplay) =>
                        <TableRow
                            key={bookingToDisplay.bookingId}
                            style={
                                bookingToDisplay.bookerId === props.currentUserID ?
                                    {backgroundColor: "rgb(206,229,255)"} : {backgroundColor: "none"}
                            }
                        >
                            <TableCell style={styleCell}>
                                <GroupsIcon/>
                            </TableCell>
                            <TableCell style={styleCell}>
                                {dayjs(bookingToDisplay.timeBegin).format("HH:mm") + "\u202F\u2013\u202F"
                                    + dayjs(bookingToDisplay.timeEnd).format("HH:mm")}
                            </TableCell>
                            <TableCell style={styleCell}>
                                {bookingToDisplay.__typename === "MeetingRoomBooking" && bookingToDisplay.meetingName}
                            </TableCell>
                            <TableCell style={styleCell}>
                                {bookingToDisplay.__typename === "MeetingRoomBooking" && bookingToDisplay.participantNumber}
                            </TableCell>
                            <TableCell style={styleCell}>
                                {bookingToDisplay.__typename === "MeetingRoomBooking" && t(getMeetingTypeLabel(bookingToDisplay.meetingType))}
                            </TableCell>
                            <TableCell style={styleCell}>
                                {bookingToDisplay.__typename === "MeetingRoomBooking" && bookingToDisplay.meetingRoomSeatingConf}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default BookingMeetingRoomList;
