import React, {useMemo, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {
    bookingTabHeaderCell,
    bookingTabTableCell,
    getSortIcon,
    getSortOptionsByColumn,
    meetingBookingSortColumns,
    StyledTabTableCell
} from "../../Utils/OwnBookingTabHelper";
import {OwnMeetingRoomBooking} from "../../types/MeetingRoomBookingListItemType";
import {formatDate, timestampToHourMinuteString} from "../../Utils/Helpers";
import {getMeetingTypeLabel} from "../../Utils/Enums";
import {useTranslation} from "react-i18next";
import useSeatConfigs from "../../hooks/useSeatConfigs";

const initialSortOptions = {
    date: 'asc',
    timeBegin: 'default',
    timeEnd: 'default',
    participantNumber: 'default',
    meetingRoomName: 'default',
    meetingName: 'default',
    meetingType: 'default',
    meetingRoomSeatingConf: 'default',
    selectedColumn: 'default'
};

interface Props {
    bookings: OwnMeetingRoomBooking[]
    selectedMRBookings: OwnMeetingRoomBooking[]
    onSelectMRBookings: (newSelection: OwnMeetingRoomBooking[]) => void
}

export const OwnMeetingRoomBookingsSortableTable: React.FC<Props> = (props) => {
    const {bookings, selectedMRBookings, onSelectMRBookings} = props;
    const [sortOptions, setSortOptions] = useState<{
        [key: string]: string
    }>(initialSortOptions);

    const showSeatingConfigColumn = useMemo(() => {
        return bookings.some(b => b.meetingRoomSeatingConf != null)
    }, [bookings]);

    const {t, i18n} = useTranslation();
    const isGermanLocale = i18n.language.startsWith('de');

    const columnHeaders = [
        {label: t('date'), key: 'date'},
        {label: t('general_meeting-room-singular'), key: 'meetingRoomName'},
        {label: t('start-time'), key: 'timeBegin'},
        {label: t('end-time'), key: 'timeEnd'},
        {label: t('meeting-name'), key: 'meetingName'},
        {label: t('number_of_participants'), key: 'participantNumber'},
        {label: t('meeting_room_type'), key: 'meetingType'},
        {label: t("general_meetroom_seatconf_col"), key: 'meetingRoomSeatingConf'}
    ];

    const sortedDetailedBookings: OwnMeetingRoomBooking[] = useMemo(() => {
        const selectedColumn = sortOptions.selectedColumn;
        const primarySortColumn = meetingBookingSortColumns.find((sortItem) => sortItem.column === selectedColumn);
        const secondarySortColumns = meetingBookingSortColumns.filter((sortItem) => sortItem.column !== selectedColumn);

        return bookings.sort((a, b) => {
            const primarySortComparison = primarySortColumn?.compare(a[primarySortColumn.column]!, b[primarySortColumn.column]!) ?? 0;
            const sortOrder = sortOptions[selectedColumn] === 'desc' ? -1 : 1;
            const comparison = sortOrder * primarySortComparison;

            //  Secondary Sorting:
            //    - If the primary sort comparison is '0' (indicating equal values), the sorting continues using the
            //      'secondarySortColumns' in a sequential manner.
            //    - Each 'secondarySortColumn' is compared in turn to break the tie in case of equal primary sort comparisons.
            //    - The 'reduce' function iterates through 'secondarySortColumns', applying comparisons until a non-zero value is found.
            //    - The result determines the final sorting order of 'a' and 'b'.

            return comparison !== 0 ? comparison : secondarySortColumns.reduce((acc, sortColumn) => {
                if (acc === 0) {
                    return sortColumn.compare(a[sortColumn.column]!, b[sortColumn.column]!);
                }
                return acc;
            }, 0);
        });
    }, [bookings, sortOptions]);


    function handleHeaderSortClick(column: string) {
        setSortOptions(getSortOptionsByColumn(column, sortOptions));
    }

    function handleRowClick(booking: OwnMeetingRoomBooking) {
        const newSelectedRows = new Set(selectedMRBookings);

        const hasSelectedBooking = Array.from(newSelectedRows).some((selectedBooking) => selectedBooking.bookingId === booking.bookingId);

        if (hasSelectedBooking) {
            newSelectedRows.forEach((selectedBooking) => {
                if (selectedBooking.bookingId === booking.bookingId) {
                    newSelectedRows.delete(selectedBooking);
                }
            });
        } else {
            newSelectedRows.add(booking);
        }
        onSelectMRBookings(Array.from(newSelectedRows));
    }

    return (
        <TableContainer>
            <Table style={{marginLeft: 0, marginRight: 0, maxWidth: '100%'}}>
                <TableHead>
                    <TableRow>
                        {columnHeaders.map((column) => {
                            if (column.key === "meetingRoomSeatingConf" && !showSeatingConfigColumn) {
                                return <></>;
                            }
                            return (
                                <TableCell
                                    key={column.key}
                                    onClick={() => handleHeaderSortClick(column.key)}
                                    style={bookingTabTableCell}
                                >
                                    <div style={bookingTabHeaderCell}>
                                        {getSortIcon(sortOptions[column.key])}
                                        {column.label}
                                    </div>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedDetailedBookings.map((item: OwnMeetingRoomBooking) => (
                        <TableRow
                            key={item.bookingId}
                            selected={selectedMRBookings.some(meetingRoomBooking => meetingRoomBooking.bookingId === item.bookingId)}
                            onClick={() => handleRowClick(item)}
                        >
                            <StyledTabTableCell>{formatDate(item.date, isGermanLocale)}</StyledTabTableCell>
                            <StyledTabTableCell>{item.meetingRoomName}</StyledTabTableCell>
                            <StyledTabTableCell>{timestampToHourMinuteString(item.timeBegin)}</StyledTabTableCell>
                            <StyledTabTableCell>{timestampToHourMinuteString(item.timeEnd)}</StyledTabTableCell>
                            <StyledTabTableCell>{item.meetingName}</StyledTabTableCell>
                            <StyledTabTableCell>{item.participantNumber}</StyledTabTableCell>
                            <StyledTabTableCell>{t(getMeetingTypeLabel(item.meetingType))}</StyledTabTableCell>
                            {showSeatingConfigColumn &&
                                <StyledTabTableCell>
                                    {item.meetingRoomSeatingConf ?? ""}
                                </StyledTabTableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
