import React, {useMemo} from "react";
import {Building, Room} from "../../API";
import {Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {gql, useMutation} from "@apollo/client";
import {updateSecureRoom} from "../../graphql/mutations";
import {useErrorContext} from "../../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";
import {EN_DASH} from "../../Utils/commons";

const HANDLE_UPDATE_ROOM_IS_ACTIVE = "updateRoomIsActive";
const HANDLE_UPDATE_ROOM_IS_PUBLIC = "updateRoomIsPublic";
const HANDLE_UPDATE_ROOM_IS_DEFAULT = "updateRoomIsDefault";

interface Props {
    rooms: [] | Room[]
    selectedRoom: Room | undefined
    selectedBuilding: Building
    setSelectedRoom: (value: Room | undefined) => void
    setShowSeatConfig: (bool: boolean) => void
    setShowRoomHintsConfig: (bool: boolean) => void
    setShowNeighborhoodConfig: (bool: boolean) => void
    setShowMeetingRoomsConfig: (bool: boolean) => void
    handleIsTimeBox: (room: Room, newValue: boolean) => void
}

const RoomManagerTableComponent: React.FC<Props> = (props) => {

    const {
        rooms,
        selectedRoom,
        selectedBuilding,
        setSelectedRoom,
        handleIsTimeBox,
    } = props
    const {reportError} = useErrorContext()
    const [updateRoomMutation] = useMutation(gql(updateSecureRoom))
    const {hasManagedNeighborhoodInRoom, hasManagementForRoom, hasManagementForBuilding} = usePermissionHelper();

    const {
        orgUnitList
    } = useMainApplicationContext()
    const roomsOfSelectedBuilding = useMemo(() => {
        return rooms
            .filter(room => room.buildingId === selectedBuilding?.buildingId)
            .filter(room => hasManagementForRoom(room))
    }, [selectedBuilding, rooms]);

    const managementForBuilding = useMemo(() => {
        return hasManagementForBuilding(selectedBuilding)
    }, [selectedBuilding]);

    const handleIsActive = (room: Room, newValue: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isActive: newValue,
                    orgUnitId: room!.orgUnitId,
                    orgUnitAdmin: room!.orgUnitAdmin
                },
                updateType: HANDLE_UPDATE_ROOM_IS_ACTIVE
            }
        }).catch((err) => reportError(err, "", "RoomManagerTableComponent handleIsActive"))
    }
    const handleIsPublic = (room: Room, newValue: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isPublic: newValue,
                    orgUnitId: room!.orgUnitId,
                    orgUnitAdmin: room!.orgUnitAdmin
                },
                updateType: HANDLE_UPDATE_ROOM_IS_PUBLIC
            }
        }).catch((err) => reportError(err, "", "RoomManagerTableComponent handleIsPublic"))
    }
    const handleIsDefault = (room: Room) => {
        roomsOfSelectedBuilding.forEach(singleRoom => {
            updateRoomMutation({
                variables: {
                    input: {
                        roomId: singleRoom.roomId,
                        isDefault: singleRoom.roomId === room.roomId,
                        orgUnitId: room!.orgUnitId,
                        orgUnitAdmin: room!.orgUnitAdmin
                    },
                    updateType: HANDLE_UPDATE_ROOM_IS_DEFAULT
                }
            }).catch((err) => reportError(err, "", "RoomManagerTableComponent handleIsDefault"))

        })

    }

    const orgNameFromId = (orgId: string) => {
        return orgId ? orgUnitList.find(orgUnit => orgUnit.orgId === orgId)!.orgName : "";
    }

    const isAnyRoomWithRoomPlan = rooms.some(room => room.roomPlanExisting)

    const {t} = useTranslation();
    // TODO: Disable public checkbox if not admin
    return (
        <TableContainer style={{maxHeight: "440px", margin: "25px 0px", tableLayout: "fixed"}}>
            <Table stickyHeader data-testid={"room-manager-table"} style={{margin: "0px", maxWidth: "100%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{wordBreak: "break-word"}}
                        >{t("room_name-column")}</TableCell>
                        <TableCell data-testid={"room_orgunit-column"}
                                   style={{wordBreak: "break-word"}}
                        >{t("daily_bookings_table_orgunit_column")}</TableCell>
                        {managementForBuilding && <TableCell
                            data-testid={"room-plan-column"}>{t("room_plan-column")}
                        </TableCell>}
                        {managementForBuilding && <TableCell
                            data-testid={"scale-factor-column"}>{t("scaleFactor-column")}
                        </TableCell>}
                        {isAnyRoomWithRoomPlan
                            && <TableCell
                                data-test-id={"seat-config-cta-column"}>
                            </TableCell>}
                        {isAnyRoomWithRoomPlan
                            && <TableCell
                                data-test-id={"roomHint-config-cta-column"}>
                            </TableCell>}
                        {isAnyRoomWithRoomPlan && <TableCell
                            data-test-id={"neighborhood-config-cta-column"}>
                        </TableCell>}
                        {isAnyRoomWithRoomPlan
                            && <TableCell data-test-id={"meetingRooms-config-cta-column"}>
                            </TableCell>}
                        {managementForBuilding && <TableCell data-testid={"room_active-column-test"}>
                            {t("room_active-column")}
                        </TableCell>}
                        {managementForBuilding && <TableCell
                            data-test-id={"time-config-checkbox"}>{t("room_time-active")}
                        </TableCell>}
                        { managementForBuilding &&
                            <TableCell
                                data-testid={"public-config-column"}>{t("room_public-active")}
                            </TableCell>
                        }
                        { managementForBuilding &&
                            <TableCell
                                data-testid={"urlkey-config-column"}>{t("room_url-key-active")}
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody data-testid={"room-list"}>
                    {roomsOfSelectedBuilding.map((room, index) => {
                            const hasNeighborhoodPermission = hasManagedNeighborhoodInRoom(room);
                            return (<TableRow data-testid={`room-${room.roomId}`}
                                              onClick={() => setSelectedRoom(room)}
                                              key={index}
                                              selected={selectedRoom && room === selectedRoom}>
                                <TableCell
                                    style={{maxWidth: "150px", wordBreak: "break-word"}}>
                                    {room.name}
                                </TableCell>
                                <TableCell
                                    style={{maxWidth: "150px", wordBreak: "break-word"}}>
                                    {orgNameFromId(room.orgUnitId!)}
                                </TableCell>
                                {managementForBuilding && <TableCell>
                                    {room.roomPlanExisting!! &&
                                        <CheckCircleIcon style={{color: "green"}}
                                                         data-testid={"check-mark"}/>}
                                </TableCell>}
                                {managementForBuilding && <TableCell>
                                    {room.roomPlanScaleFactor ?
                                        room.roomPlanScaleFactor :
                                        ""
                                    }
                                </TableCell>}
                                {isAnyRoomWithRoomPlan && <TableCell>
                                    {room.roomPlanExisting &&
                                        <Button
                                            onClick={() => props.setShowSeatConfig(true)}
                                            color={"primary"}
                                            variant={"contained"}
                                            data-testid={"seats-button"}
                                            disabled={room !== selectedRoom}
                                        >
                                            {t('seats')}
                                        </Button>}
                                </TableCell>}
                                {isAnyRoomWithRoomPlan
                                    && <TableCell>
                                        {room.roomPlanExisting && room.hasHints &&
                                            <Button
                                                onClick={() => props.setShowRoomHintsConfig(true)}
                                                color={"primary"}
                                                variant={"contained"}
                                                data-testid={"roomHints-button"}
                                                disabled={room !== selectedRoom}
                                            >
                                                {t('rooms')}
                                            </Button>}
                                    </TableCell>}
                                {isAnyRoomWithRoomPlan
                                    && <TableCell>
                                        {room.roomPlanExisting && room.hasNeighborhood && (
                                            <Button
                                                onClick={() => props.setShowNeighborhoodConfig(true)}
                                                color={"primary"}
                                                variant={"contained"}
                                                data-testid={`neighborhoods-button-${index}`}
                                                disabled={room !== selectedRoom}
                                            >
                                                {t('neighborhoods')}
                                            </Button>)}
                                    </TableCell>}
                                {isAnyRoomWithRoomPlan
                                    && <TableCell>
                                        {room.roomPlanExisting && room.hasMeetingRooms && (
                                            <Button
                                                onClick={() => props.setShowMeetingRoomsConfig(true)}
                                                color={"primary"}
                                                variant={"contained"}
                                                data-testid={`meetingRooms-button-${index}`}
                                                disabled={room !== selectedRoom}
                                            >
                                                {t('button_meeting_rooms')}
                                            </Button>)
                                        }
                                    </TableCell>}
                                {managementForBuilding && <TableCell>
                                    <Checkbox
                                        name={"isActive"}
                                        checked={!!room.isActive}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleIsActive(room, event.target.checked)}
                                        //stopPropagation wurde eingefügt, damit die TableRow beim Klicken der Checkbox nicht getriggert wird und die Buttons inaktiv bleiben
                                        onClick={(e) => e.stopPropagation()}
                                        data-testid={`room_active-checkbox-test-${room.roomId}`}
                                    />
                                </TableCell>}
                                {managementForBuilding && <TableCell>
                                    <Checkbox
                                        name={"isTimeActive"}
                                        checked={!!room.isTimeActive}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleIsTimeBox(room, event.target.checked)}
                                        //stopPropagation wurde eingefügt, damit die TableRow beim Klicken der Checkbox nicht getriggert wird und die Buttons inaktiv bleiben
                                        onClick={(e) => e.stopPropagation()}
                                        data-testid={`checkbox-isTime-${index}`}
                                    />
                                </TableCell>}

                                {managementForBuilding && <TableCell>
                                    <Checkbox
                                        name={"isPublic"}
                                        checked={room.isPublic || false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleIsPublic(room, event.target.checked)}
                                        //stopPropagation wurde eingefügt, damit die TableRow beim Klicken der Checkbox nicht getriggert wird und die Buttons inaktiv bleiben
                                        onClick={(e) => e.stopPropagation()}
                                        data-testid={`room_public-checkbox-test-${room.roomId}`}
                                    />
                                </TableCell>}
                                <TableCell
                                    style={{maxWidth: "150px", wordBreak: "break-word"}}>
                                    {(room.urlKey ?? "") === "" ? EN_DASH : room.urlKey}
                                </TableCell>
                            </TableRow>)
                        }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default RoomManagerTableComponent;
