import {SeatConfig} from "../../../API";
import {useSeatConfigsOfRoom} from "../../../hooks/useSeatConfigsOfRoom";
import {useEffect, useState} from "react";

interface UseSeatConfigMapResult {
    seatIdToSeatConfig: Map<string, SeatConfig>;
}

const NO_SEAT_CONFIGS: Map<string, SeatConfig> = new Map();

export function useSeatConfigMapByRoom(roomId: string, reloadSeatConfigsTrigger: boolean): UseSeatConfigMapResult {
    const {fetchAllSeatConfigs} = useSeatConfigsOfRoom();
    const [seatConfigs, setSeatConfigs] = useState<Map<string, SeatConfig>>(NO_SEAT_CONFIGS);
    useEffect(() => {
        if (!roomId) {
            setSeatConfigs(NO_SEAT_CONFIGS);
            return;
        }
        fetchAllSeatConfigs(roomId)
            .then(seatConfigsByRoomId => {
                if (seatConfigsByRoomId.length == 0) {
                    setSeatConfigs(NO_SEAT_CONFIGS);
                    return;
                }
                const seatConfigsMap: Map<string, SeatConfig> = new Map();
                seatConfigsByRoomId.forEach(seatConfig => {
                    seatConfigsMap.set(seatConfig.seatName, seatConfig);
                });
                setSeatConfigs(seatConfigsMap);
            });
    }, [roomId, reloadSeatConfigsTrigger]);

    return {seatIdToSeatConfig: seatConfigs};
}