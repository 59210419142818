import {gql, useLazyQuery} from "@apollo/client";
import {getNeighborhoodsByRoomId} from "../graphql/queries";
import {Neighborhood} from "../API";
import {User} from "../services/UserClient";
import {useMainApplicationContext} from "./useMainApplicationContext";
import {useEffect, useState} from "react";
import {hasUserAccessToNeighborhood} from "../Utils/permissions";


export function useHasAccessByNeighborhoodAndRoomId(roomId: string, currentUser: User) {
    const [queryNeighborhoods] = useLazyQuery(gql(getNeighborhoodsByRoomId))

    async function fetchNeighborhoodsByRoomId() {
        const variables = {roomId};
        const result = await queryNeighborhoods({variables});
        return result?.data?.getNeighborhoodsByRoomId?.items;
    }

    async function calculateIfUserHasAccessToNeighborhoods() {
        const hasNeighborhoodAccessMap: Map<string, boolean> = new Map();
        const neighborhoods = await fetchNeighborhoodsByRoomId();
        neighborhoods?.forEach((neighborhood: Neighborhood) => {
            const hasAccess = hasUserAccessToNeighborhood(neighborhood, currentUser);
            hasNeighborhoodAccessMap.set(neighborhood.neighborhoodId, hasAccess);
        });
        return hasNeighborhoodAccessMap;
    }

    return {calculateIfUserHasAccessToNeighborhoods};
}

const NO_NEIGHBORHOODS_WITH_ADMIN_RIGHTS: string[] = [];

// TODO what is the difference between neighborhood.roleIds and neighborhood.adminRoleIds
export function useIdsOfAdministratedNeighborhoodsByRoomId(roomId: string) {
    const {currentUser} = useMainApplicationContext();
    const [queryNeighborhoods] = useLazyQuery(gql(getNeighborhoodsByRoomId));
    const [neighborhoodIdsAdmin, setNeighborhoodIdsAdmin] = useState<string[]>([]);

    useEffect(() => {
        async function fetchNeighborhoodsAndUpdate() {
            const variables = {roomId};
            const result = await queryNeighborhoods({variables});
            const neighborhoods = result?.data?.getNeighborhoodsByRoomId?.items;
            const ids: string[] = []
            neighborhoods?.forEach((nbhd: Neighborhood) => {
                const hasAccess = hasUserAccessToNeighborhood(nbhd, currentUser);
                if (hasAccess) {
                    ids.push(nbhd.neighborhoodId);
                }
            })
            setNeighborhoodIdsAdmin(ids);
        }

        setNeighborhoodIdsAdmin([]);
        if (roomId) {
            fetchNeighborhoodsAndUpdate();
        }
    }, [roomId, currentUser]);

    if (neighborhoodIdsAdmin.length === 0) {
        return (
            {neighborhoodIdsUserIsAdmin: NO_NEIGHBORHOODS_WITH_ADMIN_RIGHTS}
        );
    }
    return (
        {neighborhoodIdsUserIsAdmin: neighborhoodIdsAdmin}
    );
}