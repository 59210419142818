import {Building, Inventory, Neighborhood, Room} from "../API";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import RoomPickerComponent from "./RoomPickerComponent";
import RoomPlanComponent from "./RoomPlanComponents/RoomPlanComponent";
import {Box, Button, createStyles, makeStyles, Theme} from "@material-ui/core";
import {useSeatConfigList} from "../hooks/useSeatConfigList";
import {useInventoryList} from "../hooks/useInventoryList";
import {InvType} from "../Utils/Enums";
import CollapsibleFilterComponent from "./CollapsibleFilterComponent";
import shadows from "../styles/shadows";
import borders from "../styles/borders";
import uiElementMeasures from "../styles/inputElementMeasures";
import {User} from "../services/UserClient";
import FilterContext from "../context/FilterContext";
import {useTranslation} from "react-i18next";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {filterContextTypes} from "../types/FilterContextTypes";
import {useNeighborhoodList} from "../hooks/useNeighborhoodList";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";
import {MoreHoriz} from "@material-ui/icons";
import {usePermissionHelper} from "../hooks/usePermissionHelper";
import {EditSeatBookingContextProvider} from "./EditSeatBookingDialog/EditSeatBookingFromPlanContext";
import {
    ConfirmSeatBookingDeletionContextProvider
} from "./RoomPlanComponents/Contexts/ConfirmSeatBookingDeletionFromPlanContext";


export interface SeatBookingProps {
    rooms: [] | Room [],
    currentUser: User,
    selectedDate: Date,
    onSelectedDateChange: (newSelectedDate: Date) => void,
    selectedRoom: Room | undefined,
    onSelectedRoomChange: (newSelectedRoom: Room | undefined) => void
    selectedBuilding: Building | undefined,
    setSelectedBuilding: (b: Building | undefined) => void
}

const SeatBookingComponent: React.FC<SeatBookingProps> = (props) => {
    const {
        rooms,
        currentUser,
        selectedDate,
        onSelectedDateChange,
        selectedRoom,
        onSelectedRoomChange,
        selectedBuilding,
        setSelectedBuilding
    } = props;

    const {
        orgUnitList,
        buildingList,
    } = useMainApplicationContext();

    const permissionHelper = usePermissionHelper();

    const [allNeighborhoodsOfSelectedRoom] = useNeighborhoodList(selectedRoom?.roomId, false)
    const [neighborhoodsOfSelectedRoom, setNeighborhoodsOfSelectedRoom] = useState<Neighborhood[]>(
        allNeighborhoodsOfSelectedRoom.filter(n => permissionHelper.hasAccessForNeighborhood(n)))
    const {isMobile, isSmallMobile} = useDeviceMediaType();
    const [selectedDockingstation, setSelectedDockingstation] = useState<string>("")
    const [selectedMonitor, setSelectedMonitor] = useState<string>("")
    const [isRoomDropdownFocussed, setIsRoomDropdownFocussed] = useState(false)
    const [isNeighborhoodDropdownFocused, setIsNeighborhoodDropdownFocused] = useState(false)

    const [isBuildingDropdownFocused, setIsBuildingDropdownFocused] = useState(false)
    const [isOrgUnitDropdownFocused, setIsOrgUnitDropdownFocused] = useState(false)
    const [isPickerVisible, setIsPickerVisible] = useState(!isMobile);

    const seatConfigurationList = useSeatConfigList((selectedRoom?.roomId ?? ""))
    const {inventories: allInventories} = useInventoryList()
    const [showHeightAdjustableDesks, setShowHeightAdjustableDesks] = useState<boolean>(false)
    const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood | undefined>();
    //This state variable is used so that when an already selected neighborhood is clicked again, the state changes, and it zooms on the neighborhood
    const [triggerNeighborhoodUpdate, setTriggerNeighborhoodUpdate] = useState<boolean>(false)

    const [roomFinderSelectedRoom, setRoomFinderSelectedRoom] = useState<string>();
    const [triggerRoomFinderSelectedRoomUpdate, setTriggerRoomFinderSelectedRoomUpdate] = useState<boolean>(false);

    const monitorOptions = allInventories.filter(filterMonitorsInOrgUnits)
    const dockingStationOptions = allInventories.filter(filterDockingStationsInOrgUnit)
    const hasHeightAdjustableDesk = seatConfigurationList.some(value => value.isSeatHeightAdjustable)
    const accessibleBuildings = buildingList.filter(isBuildingAssignedToRoom)//TODO check for available rooms in building

    useEffect(() => {
        setNeighborhoodsOfSelectedRoom(allNeighborhoodsOfSelectedRoom.filter(n => permissionHelper.hasAccessForNeighborhood(n)));
    }, [allNeighborhoodsOfSelectedRoom])

    const useStyles = makeStyles<Theme>(theme => createStyles({
        divSeatBooking: {
            display: "flex",
            flexDirection: "column",
        },
        divRoomPlan: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            zIndex: 1,
            flexGrow: 1
        },
        divRoomPicker: {
            position: "fixed",
            right: isSmallMobile ? "50%" : "20px",
            transform: isSmallMobile ? "translate(50%, 0)" : "none",
            marginTop: isMobile ? "60px" : "20px",
            height: "fit-content",
            maxHeight: 'calc(100vh - 80px)',
            overflowY: "auto",
            maxWidth: "370px",
            minWidth: "300px",
            zIndex: 2
        },
        boxSeatBooking: {
            borderRadius: "10px",
            border: borders.containerInputElementBorder,
            boxShadow: shadows.containerInputElementShadow,
            backgroundColor: "white",
            padding: "10px"
        },
        minButton: {
            position: "fixed",
            right: "20px",
            marginTop: "80px",
            height: "fit-content",
            alignSelf: "top",
            justifySelf: "flex-end",
            zIndex: 2
        }
    }));
    const classes = useStyles();

    const {t} = useTranslation();

    const emptyMonitor: Inventory = {
        __typename: "Inventory",
        inventoryId: "",
        orgUnitId: "",
        name: t('select_monitor'),
        type: InvType.Monitor,
        createdAt: "0",
        updatedAt: "0",
        nameLowerCased: "keinmonitor",
    };

    const emptyDockingstation: Inventory = {
        __typename: "Inventory",
        inventoryId: "",
        orgUnitId: "",
        name: t('select_docking_station'),
        type: InvType.Dockingstation,
        createdAt: "0",
        updatedAt: "0",
        nameLowerCased: "keinedocking",
    }

    useEffect(function deselectNonExistingInventoryId() {
        if (!dockingStationOptions.some(ds => ds.inventoryId === selectedDockingstation)) {
            setSelectedDockingstation(emptyDockingstation.inventoryId);
        }
        if (!monitorOptions.some(m => m.inventoryId === selectedMonitor)) {
            setSelectedMonitor(emptyMonitor.inventoryId);
        }
    }, [dockingStationOptions, monitorOptions]);

    function filterMonitorsInOrgUnits(inventoryItem: Inventory) {
        const isMonitor = inventoryItem.type === InvType.Monitor;
        const isSelectedOrgUnit = selectedRoom?.orgUnitId === inventoryItem.orgUnitId;

        return isMonitor && isSelectedOrgUnit
    }

    function filterDockingStationsInOrgUnit(inventoryItem: Inventory) {
        const isDockingStation = inventoryItem.type === InvType.Dockingstation;
        const isSelectedOrgUnit = selectedRoom?.orgUnitId === inventoryItem.orgUnitId;

        return isDockingStation && isSelectedOrgUnit;
    }

    function isBuildingAssignedToRoom(building: Building) {
        return rooms.some(room => room.buildingId === building.buildingId)
    }

    const onRoomFinderRoomSelected = useCallback((id: string) => {
        setRoomFinderSelectedRoom(id);
        setSelectedNeighborhood(undefined);
        setTriggerRoomFinderSelectedRoomUpdate(prevState => !prevState);
    }, []);

    const handleSelectedRoomChange = (newSelectedRoom: Room | undefined) => {
        setRoomFinderSelectedRoom(undefined);
        setSelectedNeighborhood(undefined);
        onSelectedRoomChange(newSelectedRoom);
    };

    const onSelectedNeighborhoodChange = useCallback((neighborhood: Neighborhood | undefined) => {
        setSelectedNeighborhood(neighborhood);
        if (neighborhood !== undefined) {
            setRoomFinderSelectedRoom(undefined);
        }
        setTriggerNeighborhoodUpdate(!triggerNeighborhoodUpdate);
    }, [triggerNeighborhoodUpdate])

    const hasDockingStations = dockingStationOptions.length > 0;
    const hasMonitors = monitorOptions.length > 0;
    const canFilter = hasDockingStations || hasMonitors || hasHeightAdjustableDesk;

    //TODO remove orgunits
    const canDisplay = rooms.length > 0 && orgUnitList.length > 0 && accessibleBuildings.length > 0


    const ProviderValue: filterContextTypes = useMemo((): filterContextTypes => {
        return {
            selectedMonitor,
            setSelectedMonitor,
            selectedDockingstation,
            setSelectedDockingstation,
            showHeightAdjustableDesks,
            setShowHeightAdjustableDesks,
            seatConfigurationList,
            monitorOptions,
            dockingStationOptions,
            allInventories,
            isRoomDropdownFocussed,
            setIsRoomDropdownFocussed,
            isNeighborhoodDropdownFocused,
            setIsNeighborhoodDropdownFocused,
            isBuildingDropdownFocused,
            setIsBuildingDropdownFocused,
            isOrgUnitDropdownFocused,
            setIsOrgUnitDropdownFocused
        }
    }, [allInventories,
        dockingStationOptions,
        isBuildingDropdownFocused,
        isRoomDropdownFocussed,
        isNeighborhoodDropdownFocused,
        monitorOptions,
        seatConfigurationList,
        selectedDockingstation,
        selectedMonitor,
        showHeightAdjustableDesks]);

    return (
        <div data-testid={"seatBookingComponent"} className={classes.divSeatBooking}>
            {canDisplay ?
                <FilterContext.Provider value={ProviderValue}>
                    <div className={classes.divRoomPlan}>
                        {selectedRoom && (
                            <EditSeatBookingContextProvider>
                                <ConfirmSeatBookingDeletionContextProvider>
                                    <RoomPlanComponent
                                        buildingRoleIds={selectedBuilding?.roleIds ?? []}
                                        neighborhoodsOfSelectedRoom={neighborhoodsOfSelectedRoom}
                                        selectedNeighborhood={selectedNeighborhood}
                                        triggerNeighborhoodUpdate={triggerNeighborhoodUpdate}
                                        roomFinderSelectedRoom={roomFinderSelectedRoom}
                                        triggerRoomFinderSelectedRoomUpdate={triggerRoomFinderSelectedRoomUpdate}
                                        room={selectedRoom}
                                        building={selectedBuilding}
                                        date={selectedDate}
                                        currentUser={currentUser}
                                    />
                                </ConfirmSeatBookingDeletionContextProvider>
                            </EditSeatBookingContextProvider>
                        )}
                    </div>
                    {isMobile && !isPickerVisible &&
                        (<Button variant="contained" className={classes.minButton}
                                 onClick={() => setIsPickerVisible(true)}
                        >{<MoreHoriz/>}
                        </Button>)}
                    <div className={classes.divRoomPicker} hidden={!isPickerVisible}>
                        <Box className={classes.boxSeatBooking}>
                            <RoomPickerComponent
                                neighborhoodsOfSelectedRoom={allNeighborhoodsOfSelectedRoom}
                                //Only pass down the rooms that have a building assigned
                                rooms={rooms.filter(room => room.buildingId)}
                                accessibleBuildings={accessibleBuildings}
                                selectedBuilding={selectedBuilding}
                                setSelectedBuilding={setSelectedBuilding}
                                selectedDate={selectedDate}
                                selectedRoom={selectedRoom}
                                selectedNeighborhood={selectedNeighborhood}
                                onSelectedRoomChange={handleSelectedRoomChange}
                                setSelectedDate={onSelectedDateChange}
                                onSelectedNeighborhoodChange={onSelectedNeighborhoodChange}
                                setVisible={setIsPickerVisible}
                                onRoomFinderRoomSelected={onRoomFinderRoomSelected}/>
                            {selectedRoom &&
                                canFilter && (
                                    <div style={{marginTop: uiElementMeasures.marginBetweenElementsInColumn}}>
                                        <CollapsibleFilterComponent
                                            hasDockingStations={hasDockingStations}
                                            hasMonitors={hasMonitors}
                                            hasHeightAdjustableDesk={hasHeightAdjustableDesk}
                                            dockingStationListItems={[
                                                emptyDockingstation,
                                                ...dockingStationOptions
                                            ]}
                                            monitorListItems={[emptyMonitor, ...monitorOptions]}
                                        />
                                    </div>
                                )}
                        </Box>
                    </div>
                </FilterContext.Provider>
                :
                t('seat_booking_error_no_rooms_configured')}
        </div>
    );
};
export default SeatBookingComponent;
