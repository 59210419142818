import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {CustomIconButton} from "./CustomIconButton";
import {styled} from "@material-ui/core";

export interface Props {
    displayText: string
    onEditButtonPressed: () => void,
    onDeleteButtonPressed: () => void,
}

const StyledEditCustomIconButton = styled(CustomIconButton)({
    marginRight: "4px",
    width: "30px",
});
const StyledDeleteCustomIconButton = styled(CustomIconButton)({
    width: "30px"
});


export const EditAndDeleteListItem: React.FC<Props> = ({displayText, onEditButtonPressed, onDeleteButtonPressed}) => {

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{
                padding: "4px 0px 4px 4px",
                boxSizing: "border-box",
            }}
        >
            <Typography
                variant="caption"
                sx={{
                    fontWeight: "bold",
                    marginRight: "10px",
                    fontSize: "13px"
                }}
            >
                {displayText}
            </Typography>
            <Box>
                <StyledEditCustomIconButton
                    icon={<EditIcon/>}
                    onClick={onEditButtonPressed}
                />
                <StyledDeleteCustomIconButton icon={<DeleteIcon/>} onClick={onDeleteButtonPressed}/>
            </Box>
        </Stack>
    );
}

