import {useRouterContext} from "../hooks/useRouterContext";
import {useRoomList} from "../hooks/useRoomList";
import {useParams, useHistory} from "react-router-dom";
import InfoDialog from "../components/InfoDialog";
import MainPage from "../pages/MainPage";
import React, {useEffect, useState} from "react";
import {set} from "js-cookie";
import {useTranslation} from "react-i18next";

export const SeatJumpComponent = () => {
    const routerContext = useRouterContext()
    const history = useHistory();
    const {rooms} = useRoomList();
    const params: { roomKey: string, seatId: string, b64nav: string } = useParams();
    const [showWrongRoomPlanKey, setShowWrongRoomPlanKey] = useState<boolean | undefined>()
    const {t} = useTranslation();
    useEffect(() => {
        if (params.b64nav) {
            const decoded = atob(params.b64nav);
            const parsed = JSON.parse(decoded);
            if (parsed.roomKey) {
                history.push(`/seat/${parsed.roomKey}/${parsed.seatId}`)
            }
        }

        const roomByKey = rooms.filter(room => room.roomPlanExisting && room.urlKey === params.roomKey)[0];
        if (rooms.length > 0 && params) {
            if (roomByKey) {
                setShowWrongRoomPlanKey(false)
                routerContext.seatId = decodeURIComponent(params.seatId);
                routerContext.roomPlanKey = params.roomKey;
                routerContext.shouldTrigger = true;
            } else {
                setShowWrongRoomPlanKey(true)
                routerContext.seatId = undefined;
                routerContext.roomPlanKey = undefined;
            }
        }
    }, [rooms, params])

    return (
        <>
            {showWrongRoomPlanKey && <InfoDialog
                open={showWrongRoomPlanKey}
                onClose={() => setShowWrongRoomPlanKey(false)}
                title={''}
                content={t('error_wrong_room_plan-key')}
            />}
            <MainPage/>
        </>
    )
}
