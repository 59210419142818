import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import {MeetingRoom} from "../../../API";
import React, {useMemo} from "react";
import {useMainApplicationContext} from "../../../hooks/useMainApplicationContext";
import MeetingRoomComponent from "../MeetingRoomComponent";

interface MeetingRoomOverlayProps {
    onMeetingRoomClick: (meetingRoomBookings: MeetingRoomBookings) => void
    meetingRoomBookings: MeetingRoomBookings[];
    meetingRooms: MeetingRoom[]
    userIsBuildingAdmin: boolean
    isTimeBookingActive: boolean
    userIsOrgUnitAdminOfRoom: boolean
    neighborhoodAdminIds: string[]
}

export const MeetingRoomOverlay: React.FC<MeetingRoomOverlayProps> = (props) => {
    const {
        meetingRoomBookings, userIsBuildingAdmin,
        onMeetingRoomClick, isTimeBookingActive, meetingRooms,
        userIsOrgUnitAdminOfRoom, neighborhoodAdminIds
    } = props;

    const {currentUser} = useMainApplicationContext();

    function hasAccessToNeighborhoodById(neighborhoodId: string) {
        return neighborhoodAdminIds.includes(neighborhoodId) || neighborhoodId === "" || userIsOrgUnitAdminOfRoom || currentUser?.isAdmin;
    }

    const memoizedMeetingRoomComponentList = useMemo(() => {
        return meetingRoomBookings.map((meetingRoomBooking: MeetingRoomBookings) => {
            // TODO refactor access handling
            const hasAccess = userIsBuildingAdmin || hasAccessToNeighborhoodById(meetingRoomBooking.meetingRoom.neighborhoodId);

            return <MeetingRoomComponent
                key={meetingRoomBooking.meetingRoom.meetingRoomID}
                onClick={onMeetingRoomClick}
                meetingRoomBookings={meetingRoomBooking}
                isTimeBookingActive={isTimeBookingActive}
                meetingRoomsCurrentRoom={meetingRooms}
                hasAccess={hasAccess}
            />
        })

    }, [meetingRoomBookings, userIsBuildingAdmin, onMeetingRoomClick, isTimeBookingActive]);

    return <>{memoizedMeetingRoomComponentList}</>;
}