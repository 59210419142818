import OfficeHintComponent, {HintInterface} from "../OfficeHintComponent";
import React, {useMemo} from "react";

interface HintOverlayProps {
    hintArray: HintInterface[]
}

export const HintOverlay: React.FC<HintOverlayProps> = (props) => {
    const {hintArray} = props;

    const memoizedHintComponentList = useMemo(() => {
        return hintArray.map((hint: HintInterface) => {
            return <OfficeHintComponent hint={hint} key={hint.hintId}/>
        })
    }, [hintArray]);

    return <>{memoizedHintComponentList}</>;
}