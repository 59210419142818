import {MeetingRoomBookingP} from "../../../types/PermissionHandling";
import {MeetingRoom} from "../../../API";
import {MeetingRoomSVGData} from "../../../Utils/svgPlans/meetingRooms";
import {useMemo} from "react";
import {MeetingRoomInterface} from "../MeetingRoomComponent";
import {MeetingRoomType} from "../../../Utils/Enums";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";

export function useMeetingRoomOverlayData(roomId: string, bookings: MeetingRoomBookingP[], meetingRooms: MeetingRoom[], meetingRoomSVGData: MeetingRoomSVGData[]) {
    return useMemo(() => {
        const meetingRoomDBAndSVGData: MeetingRoomInterface[] = [];
        const idToMeetingRoom = new Map(meetingRooms.map((mr) => [mr.meetingRoomId, mr]));

        meetingRoomSVGData.forEach((svgData) => {
            const id = svgData.meetingRoomId;
            const dbMeetingRoom = idToMeetingRoom.get(id);

            let meetingRoomCapacity = dbMeetingRoom?.capacity;
            if (meetingRoomCapacity === null || meetingRoomCapacity === undefined) {
                meetingRoomCapacity = svgData.roomCap
            }

            let meetingRoomName = dbMeetingRoom?.name ?? svgData.meetingRoomId;
            if (meetingRoomName.length > 60) {
                meetingRoomName = meetingRoomName.substring(0, 60);
            }

            meetingRoomDBAndSVGData.push({
                meetingRoomSeatConfigurations: dbMeetingRoom?.seatingConfNames ?? [],
                roomId: roomId,
                neighborhoodId: svgData.neighborhoodId,
                meetingRoomID: id,
                meetingRoomName: meetingRoomName,
                roomCap: meetingRoomCapacity,
                roleIds: dbMeetingRoom?.roleIds ?? [],
                isBookable: dbMeetingRoom?.isBookable ?? false,
                type: dbMeetingRoom?.type ?? MeetingRoomType.INTERNAL,
                equipmentIds: dbMeetingRoom?.equipmentIds ?? [],
                meetingRoomSeatingConfNames: dbMeetingRoom?.seatingConfNames ?? [],
                meetingRoomSeatingConfDays: dbMeetingRoom?.seatingConfDays ?? 0,
                meetingRoomSeatingConfImageId: dbMeetingRoom?.seatingConfImageId ?? '',
                meetingRoomGroupPosition: svgData.meetingRoomGroupPosition,
                meetingRoomPosition: svgData.meetingRoomPosition,
                meetingRoomDimension: svgData.meetingRoomDimension,
                meetingRoomShape: svgData.meetingRoomShape,
                meetingRoomIconPosition: svgData.meetingRoomIconPosition,
                meetingRoomIconDimension: svgData.meetingRoomIconDimension
            });
        });

        return meetingRoomDBAndSVGData
            .map((meetingRoom: MeetingRoomInterface): MeetingRoomBookings => {
                let bookingsOfRoom = bookings
                    .filter(bi => bi.meetingRoomId === meetingRoom.meetingRoomID && bi.roomId === roomId)
                //TODO verify that these bookings are not used for editing/deleting
                return {
                    meetingRoom: meetingRoom,
                    bookings: bookingsOfRoom,
                }
            })
    }, [roomId, bookings, meetingRooms, meetingRoomSVGData]);
}