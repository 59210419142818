import {Dimensions, Position} from "../../types/PositioningAndDimensionTypes";

export interface HintSVGData {
    hintId: string
    hintIconPosition: Position
    hintIconDimension: Dimensions
}

function getHintSVGRectElements(xmlDocument: XMLDocument) {
    const officeElement = xmlDocument.getElementById("offices");
    return officeElement?.querySelectorAll("rect") ?? [];
}

export function removeHintElementsFromXMLDocument(xmlDocument: XMLDocument) {
    const hintRects = getHintSVGRectElements(xmlDocument);
    hintRects.forEach(hint => {
        hint.remove();
    })
}

export function extractHintSVGData(xmlDocument: XMLDocument) {
    const hintRects = getHintSVGRectElements(xmlDocument);
    let hintData: HintSVGData[] = []
    hintRects.forEach(hint => {
        hintData.push({
            hintId: hint.getAttribute("id") ?? "0",
            hintIconPosition: {
                x: parseInt(hint?.getAttribute("x") ?? "0"),
                y: parseInt(hint?.getAttribute("y") ?? "0"),
                transform: hint?.getAttribute("transform") ?? undefined
            },
            hintIconDimension: {
                width: parseInt(hint?.getAttribute("width") ?? "0"),
                height: parseInt(hint?.getAttribute("height") ?? "0"),
            }
        });

    })
    return hintData;
}