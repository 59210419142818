import {PositionStr} from "../../types/PositioningAndDimensionTypes";

export interface SeatSVGData {
    seatID: string
    seatPosition: PositionStr
    tablePosition: PositionStr
    bookerNamePosition: PositionStr
    tableTextPosition: PositionStr
    neighborhoodId: string
}

function getSeatSVGGElements(xmlDocument: XMLDocument) {
    const seatsGroupElement = xmlDocument.getElementById("seats");
    return seatsGroupElement?.querySelectorAll("g") ?? [];
}

export function removePartsOfSeatElements(xmlDocument: XMLDocument) {
    const seatGElements = getSeatSVGGElements(xmlDocument);
    seatGElements.forEach(element => {
        element.querySelector(".bookerName")?.remove();
        element.querySelector(".seatName")?.remove();
    });
}

export function extractSeatData(xmlDocument: XMLDocument) {
    const seatGElements = getSeatSVGGElements(xmlDocument);

    const seats: SeatSVGData[] = [];
    seatGElements.forEach(element => {
        const seat = element;
        const table = seat.querySelector("rect");
        const bookerName = seat.querySelector(".bookerName");
        const seatName = seat.querySelector(".seatName");

        seats.push({
            seatID: seat.getAttribute("id") ?? "0",
            seatPosition: {
                x: seat.getAttribute("x") ?? "0",
                y: seat.getAttribute("y") ?? "0",
                transform: seat?.getAttribute("transform") ?? undefined
            },
            tablePosition: {
                x: table?.getAttribute("x") ?? "0",
                y: table?.getAttribute("y") ?? "0",
                transform: table?.getAttribute("transform") ?? undefined
            },
            bookerNamePosition: {
                x: bookerName?.getAttribute("x") ?? "0",
                y: bookerName?.getAttribute("y") ?? "0",
                transform: bookerName?.getAttribute("transform") ?? undefined
            },
            tableTextPosition: {
                x: seatName?.getAttribute("x") as string ?? "0",
                y: seatName?.getAttribute("y") as string ?? "0",
                transform: seatName?.getAttribute("transform") ?? undefined
            },
            neighborhoodId: seat.getAttribute("neighborhoodId") ?? "",
        });
    });
    return seats;
}