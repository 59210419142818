import {Neighborhood} from "../../API";
import {reformatNeighborhoodId} from "../Helpers";
import {createSvgTspan, removeChildrenOfSvgElementBySelector} from "./svgPlan";
import {getChunksFromString} from "../stringUtils";

export const NEIGHBORHOOD_LABELS_ID = "_neighboorhoodLabels" // TODO fix typo "boor" in plans?

export function getNeighborhoodLabelTextSVGElements(xmlDocument: XMLDocument) {
    return xmlDocument
        .getElementById(NEIGHBORHOOD_LABELS_ID)
        ?.querySelectorAll("text") ?? [];
}

export function removeNeighborhoodTspans(xmlDocument: XMLDocument) {
    const neighborhoodLabelTextSVGElements = getNeighborhoodLabelTextSVGElements(xmlDocument);
    neighborhoodLabelTextSVGElements.forEach(textElem => {
        removeChildrenOfSvgElementBySelector(textElem, "tspan");
    });
}


export function splitNeighborhoodName(neighborhoodName: string, maxLen: number): string[] {
    const nameParts: string[] = [];
    const namesSplitBySpace = neighborhoodName.split(" ");
    for (const nameBySpace of namesSplitBySpace) {
        if (nameBySpace.length > maxLen) {
            // if the name part is too long, then we hard break it
            getChunksFromString(nameBySpace, maxLen)
                ?.forEach(chunkStr => nameParts.push(chunkStr.trim()));
        } else {
            if (nameParts.length === 0) {
                nameParts.push("");
            }
            let nameAppendCandidate = `${nameParts[nameParts.length - 1]} ${nameBySpace}`.trim();
            if (nameAppendCandidate.length <= maxLen) {
                // if it fits the length, append this part to the previous part
                nameParts[nameParts.length - 1] = nameAppendCandidate;
            } else {
                // add it as new part (line) otherwise
                nameParts.push(nameBySpace);
            }
        }
    }
    return nameParts;
}

export function updateSVGNeighborhoodLabels(xmlDocument: XMLDocument, neighborhoods: Neighborhood[]) {
    const neighborhoodBaseSVGElement = xmlDocument.getElementById(NEIGHBORHOOD_LABELS_ID);
    if (neighborhoodBaseSVGElement === null) {
        return;
    }
    const neighborhoodLabelTextSVGElements = getNeighborhoodLabelTextSVGElements(xmlDocument);
    if (neighborhoodLabelTextSVGElements.length === 0) {
        return;
    }

    const neighborhoodToId = new Map(neighborhoods.map(nbhd => [nbhd.neighborhoodId, nbhd]));

    const offsetX = 6;

    neighborhoodLabelTextSVGElements.forEach(svgTextElem => {
        const data = extractNeighborhoodSVGData(svgTextElem);
        if (data === null){
            return;
        }
        if (data.neighborhoodId === null) {
            return;
        }

        const dbNeighborhood = neighborhoodToId.get(data.neighborhoodId);
        const neighborhoodName = (dbNeighborhood?.name ?? reformatNeighborhoodId(data.neighborhoodId)).trim()

        const nameRows = splitNeighborhoodName(neighborhoodName, data.maxLen);

        // if multiple lines, shift the label upwards
        if (data.labelPos === "top") {
            const labelX = `${+data.initX + offsetX * (nameRows.length - 1)}`;
            svgTextElem.setAttribute("x", labelX);
        }

        // each line of text (name part) is a new tspan, which has to be shifted along X axis
        for (let i = 0; i < nameRows.length; i++) {
            let tspanX;
            if (data.labelPos === "top") {
                tspanX = +data.initX + offsetX * (nameRows.length - 1 - i);
            } else {
                tspanX = +data.initX - offsetX * i;
            }

            const text = nameRows[i];
            const tspanId = `tspan-${text}-${i}`;
            const newTspan = createSvgTspan(text, tspanId, tspanX.toString(), data.y);
            svgTextElem.appendChild(newTspan);
        }
    });
}

interface NeighborhoodSVGTextElemData {
    neighborhoodId: string
    maxLen: number
    y: string
    initX: string
    labelPos: "top" | "bottom"
}

export function extractNeighborhoodSVGData(textElem: SVGTextElement): NeighborhoodSVGTextElemData | null {
    const neighborhoodId = textElem.getAttribute("neighborhoodId");
    const labelPos = textElem.getAttribute("pos");
    const maxLen = textElem.getAttribute("maxLength");
    const initX = textElem.getAttribute("initX");
    const y = textElem.getAttribute("y");

    if (neighborhoodId === null || !labelPos || !maxLen || !Number.isInteger(+maxLen) || !initX || !y) {
        return null;
    }

    if (labelPos !== "top" && labelPos !== "bottom") {
        return null;
    }

    return (
        {
            neighborhoodId,
            maxLen: +maxLen,
            y,
            initX,
            labelPos
        }
    );
}