import {BrowserRouter, Route, Switch} from "react-router-dom";
import MainPage from "../pages/MainPage";
import React from "react";
import RouterContext from "../context/RouterContext";
import {RouterContextTypes} from "../types/RouterContextTypes";
import {SeatJumpComponent} from "./SeatJumpComponent";

export function RouterComponent() {
    const routerContextValue: RouterContextTypes = {seatId: '', roomPlanKey: '', shouldTrigger: false};
    return (
        <BrowserRouter>
            <RouterContext.Provider value={routerContextValue}>
                <Switch>
                    <Route exact path="/seat/:roomKey/:seatId" component={SeatJumpComponent}/>
                    <Route exact path="/&state=:b64nav" component={SeatJumpComponent}/>
                    <Route exact path="*" component={MainPage}/>
                </Switch>
            </RouterContext.Provider>
        </BrowserRouter>
    )
}
