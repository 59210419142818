import {Box, IconButton, makeStyles, Paper, TextField, Tooltip} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {useEffect, useRef, useState} from "react";
import {maternaTheme} from "../../styles/materna_theme";
import {useTranslation} from "react-i18next";


interface Props {
    selectedConfiguration: string | undefined,
    seatConfigurations: string[],
    showCreateSeatConfiguration: boolean
    setShowCreateSeatConfiguration: (value: boolean) => void
    saveSelectedSeatConfiguration: (value: string) => void
}

const CreateOrUpdateMeetingRoomSeatConfiguration: React.FC<Props> = (props) => {
    const {
        selectedConfiguration,
        seatConfigurations,
        showCreateSeatConfiguration,
        setShowCreateSeatConfiguration,
        saveSelectedSeatConfiguration
    } = props;

    const [seatConfName, setSeatConfName] = useState<string | undefined>(undefined);
    const [showNameNotValid, setShowNameNotValid] = useState(false);


    // to focus on first input
    const nameInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (nameInputRef?.current !== null) {
            nameInputRef.current.focus();
        }
    }, [])

    useEffect(() => {
        if (selectedConfiguration) {
            setSeatConfName(selectedConfiguration)
        }
    }, [selectedConfiguration]);

    const {t} = useTranslation();
    const useStyles = makeStyles({
        btnEnabled: {
            color: "green"
        },
        btnDisabled: {
            color: "disabled"
        },
        txtName: {
            [maternaTheme.breakpoints.up('xs')]: {
                width: "40%",
            },
            [maternaTheme.breakpoints.up('lg')]: {
                width: "40%",
            },
            [maternaTheme.breakpoints.up('xl')]: {
                width: "65%",
            },
        },
        scaleFactorInput: {
            width: "6rem",
            marginLeft: "10px"
        }
    });
    const classes = useStyles();


    // maybe we need to allow after image or name
    const isSaveButtonEnabled = true;

    const handleChangeSeatConfName = (seatConfName: string) => {
        setSeatConfName(seatConfName)
    }

    const handleSave = async () => {
        if (seatConfName) {
            saveSelectedSeatConfiguration(seatConfName);
            setShowCreateSeatConfiguration(false);
        }
    }

    const handleNameBlur = () => {
        setShowNameNotValid(seatConfigurations.filter(config => config !== selectedConfiguration).some(configuration => configuration === seatConfName))
    }

    return (<Paper style={{
        marginTop: "0.625rem",
        padding: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }}>
        <Box style={{
            paddingTop: "1rem",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "75%"
        }}>
            <TextField
                className={classes.txtName}
                style={{marginRight: "10px", width: "90%"}}
                label={t('meeting-room-seatconf-name')}
                value={seatConfName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSeatConfName(e.target.value)}
                onBlur={handleNameBlur}
                error={showNameNotValid}
                helperText={showNameNotValid && t("meeting_room_catering-opt-name_not-valid")}
                inputRef={nameInputRef}
                inputProps={{
                    "data-testid": "seatConfiguration-name"
                }}/>
        </Box>
        <Box style={{
            paddingTop: "1rem",
            display: "flex",
            justifyContent: "flex-end",
            width: "25%"
        }}>
            <Tooltip title={t('confirm_dialog_ok_button-text')}>
            <span>
                <IconButton onClick={handleSave} disabled={!isSaveButtonEnabled || showNameNotValid}
                            data-testid={"save-btn"}>
                <SaveIcon
                    className={isSaveButtonEnabled && !showNameNotValid ? classes.btnEnabled : classes.btnDisabled}
                    style={{cursor: "pointer"}} fontSize="large"/>
                </IconButton>
            </span>
            </Tooltip>
            <Tooltip title={t('cancel')}>
                <IconButton onClick={() => setShowCreateSeatConfiguration(false)} data-testid={"cancel-btn"}>
                    <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
                </IconButton>
            </Tooltip>
        </Box>
    </Paper>);
}
export default CreateOrUpdateMeetingRoomSeatConfiguration
