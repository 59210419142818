import {DimensionsStr} from "../../types/PositioningAndDimensionTypes";

export function getSVGViewBoxDimensions(xmlDocument: XMLDocument): DimensionsStr {
    const svgElements = xmlDocument.getElementsByTagName("svg");
    const firstSVGElement = svgElements?.[0];
    const width = firstSVGElement?.getAttribute("width") ?? "";
    const height = firstSVGElement?.getAttribute("height") ?? "";
    return {width, height};
}

export function updateTspanContent(baseElement: Element, selector: string, value: string, label?: string) {
    const element = baseElement.querySelector(selector);
    const tspan = element?.querySelector("tspan");
    if (!tspan) {
        return;
    }
    if (label) {
        tspan.textContent = `${label}: ${value}`;
    } else {
        tspan.textContent = value;
    }
}

export function createSvgTspan(text: string, id: string, x: string, y: string) {
    const tspan = document.createElement("tspan");
    tspan.id = id;
    tspan.textContent = text;
    tspan.setAttribute("x", x);
    tspan.setAttribute("y", y);
    return tspan;
}

export function removeChildrenOfSvgElementBySelector(svgElement: SVGElement, selector: string) {
    svgElement.querySelectorAll(selector).forEach(childElem => svgElement.removeChild(childElem));
}