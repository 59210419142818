import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Tooltip,
} from '@material-ui/core';
import {MeetingRoom, MeetingRoomEquipment} from "../API";
import MultiSelectComboBox from "./MultiselectCombobox";
import {useTranslation} from "react-i18next";
import {getMeetingRoomTypeLabel, MeetingRoomType} from "../Utils/Enums";
import {useRoleList} from "../hooks/useRoleList";
import MultiSelectorDuplicities from "./MultiSelectorDuplicities";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import DeleteEntryConfirmationDialogComponent from "./DeleteEntryConfirmationDialogComponent";
import CreateOrUpdateMeetingRoomSeatConfiguration from "./RoomManager/CreateOrUpdateMeetingRoomSeatConfiguration";
import {Stack, styled} from "@mui/material";
import SeatConfigImagePreview from "./MultiBookingDialogMeetingRoom/SeatConfigImagePreview";
import {UploadFile} from "@mui/icons-material";
import {useFileUpload} from "../hooks/useFileUpload";
import {useMeetingRoomSeatConfigurations} from "../hooks/useMeetingRoomSeatConfigurations";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Divider from "@material-ui/core/Divider";
import Sit2GetherAddIcon from "../styles/Sit2GetherAddIcon";
import Edit from "@material-ui/icons/Edit";

interface ConfigureMeetingRoomProps {
    open: boolean;
    onClose: () => void;
    onSave: (meetingRoom: MeetingRoom, leaveEditMeetingRoomOpen?: boolean) => void;
    buildingId: string | null | undefined;
    selectedMeetingRoom: MeetingRoom | null;
    meetingRooms: MeetingRoom[];
    meetingRoomEquipment: MeetingRoomEquipment[];
    buildingEquipment: MeetingRoomEquipment[];
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditMeetingRoom: React.FC<ConfigureMeetingRoomProps> = (props: ConfigureMeetingRoomProps
) => {
    const {
        open,
        onClose,
        onSave,
        selectedMeetingRoom,
        meetingRooms,
        buildingEquipment
    } = props
    const [name, setName] = useState<string>('');
    const [capacity, setCapacity] = useState<number | string>('');
    const [type, setType] = useState<string>(MeetingRoomType.INTERNAL);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isCapacityValid, setIsCapacityValid] = useState(true);
    const [isBookable, setIsBookable] = useState(selectedMeetingRoom?.isBookable)
    const {roles: availableRoles} = useRoleList();
    const {t} = useTranslation();
    const [filteredEquipment, setFilteredEquipment] = useState<MeetingRoomEquipment[]>([]);
    const [selectedSeatConfiguration, setSelectedSeatConfiguration] = useState<string | undefined>(undefined);
    const [seatConfigurations, setSeatConfigurations] = useState<string[]>([])

    const [showDeleteSeatConfigurationDialog, setShowDeleteSeatConfigurationDialog] = useState<boolean>(false)
    const [isShowDeletionAlert, setIsShowDeletionAlert] = useState(false)
    const [showCreateSeatConfiguration, setShowCreateSeatConfiguration] = useState(false)
    const [seatConfigurationImageUrl, setSeatConfigurationImageUrl] = useState<string | undefined>(undefined);
    const [seatConfigurationImageId, setSeatConfigurationImageId] = useState<string | undefined>(undefined);
    const [seatConfigurationDaysInAdvance, setSeatConfigurationDaysInAdvance] = useState<number>(0);
    const {uploadImage, getImageUrl} = useFileUpload();

    useEffect(() => {
        setShowCreateSeatConfiguration(false);
    }, [selectedMeetingRoom]);

    useEffect(() => {
        if (seatConfigurationImageId && selectedMeetingRoom?.roomId) {
            getImageUrl(selectedMeetingRoom?.roomId, seatConfigurationImageId)
                .then((url) => setSeatConfigurationImageUrl(url || ""))
        }
    }, [seatConfigurationImageId]);

    const StyledSeatConfigButton = styled(Button)({
        minWidth: "0",
        '& .MuiButton-startIcon': {
            margin: 0,
        },
    });

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    };

    const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValueNumber = parseInt(e.target.value);

        if (!isNaN(newValueNumber)) setCapacity(newValueNumber)
        else setCapacity('')
    };

    const handleNameBlur = () => {
        validateName();
    };

    const handleCapacityBlur = () => {
        validateCapacity();
    };

    const handleTypeChange = (e: React.ChangeEvent<{
        value: unknown
    }>) => {
        setType(e.target.value as string);
    };

    const handleRolesChange = (selectedRoles: string[]) => {
        setSelectedRoles(selectedRoles);
    };

    const handleIsBookableChange = () => {
        setIsBookable(prev => !prev);
    }

    const handleChangeSeatConfDays = (days: number) => {
        setSeatConfigurationDaysInAdvance(days)
    }

    const handleChangeSeatConfImage = async (image: File | undefined) => {
        if (image === undefined) {
            return
        }
        if (selectedMeetingRoom?.roomId) {
            const imageId = await uploadImage(selectedMeetingRoom?.roomId, image)
            setSeatConfigurationImageId(imageId)
        }
    }


    const handleSave = () => {
        if (selectedMeetingRoom !== null) {
            const updatedMeetingRoom: MeetingRoom = {
                ...selectedMeetingRoom,
                type,
                roleIds: selectedRoles,
                isBookable: isBookable!,
                equipmentIds: filteredEquipment.map(equipment => equipment.equipmentId) ?? [],
                seatingConfNames: seatConfigurations,
                seatingConfImageId: seatConfigurationImageId,
                seatingConfDays: seatConfigurationDaysInAdvance
            };
            updatedMeetingRoom.roleIds = selectedRoles.map(role => {
                return availableRoles.find(roleObject => roleObject.roleName === role)?.roleId;
            }).filter((roleName): roleName is string => Boolean(roleName)) ?? [];

            if (typeof capacity === 'number') {
                updatedMeetingRoom.capacity = capacity;
            } else {
                updatedMeetingRoom.capacity = undefined;
            }

            const trimmedName = name.trim();
            if (selectedMeetingRoom.name !== trimmedName) {
                updatedMeetingRoom.name = trimmedName;
            }

            onSave(updatedMeetingRoom);
        }
        onClose();
    };


    const validateName = useCallback(() => {
        if (selectedMeetingRoom) {
            const trimmedName = name.trim();
            setIsNameValid(trimmedName !== '' && /^\S.{0,59}$/.test(trimmedName) && !meetingRooms
                .filter(meetingRoom => meetingRoom.name !== selectedMeetingRoom.name
                    && meetingRoom.roomId === selectedMeetingRoom.roomId)
                .some(meetingRoom => meetingRoom.nameLowerCased === trimmedName.toLowerCase()));
        }
    }, [meetingRooms, name, selectedMeetingRoom])

    const validateCapacity = useCallback(() => {
        let isCapacityValid = false;
        if (typeof capacity === 'number') {
            isCapacityValid = capacity >= 0 && capacity <= 200;
        }

        setIsCapacityValid(isCapacityValid);
    }, [capacity])

    useEffect(function populateSelectedMeetingRoomInformation() {
        if (selectedMeetingRoom) {
            setName(selectedMeetingRoom.name ?? '');
            setCapacity(selectedMeetingRoom.capacity ?? '');
            setType(selectedMeetingRoom.type ?? MeetingRoomType.INTERNAL);
            setIsBookable(selectedMeetingRoom.isBookable);
            const equipmentInMeetingRoom = selectedMeetingRoom.equipmentIds?.filter(id => {
                return buildingEquipment.some(el => el.equipmentId === id);
            }).map(id => buildingEquipment.find(el => el.equipmentId === id)!) ?? []
            setFilteredEquipment(equipmentInMeetingRoom)
            const roleNames = getRoleNamesOfSelectedMeetingRoom(selectedMeetingRoom);
            setSelectedRoles(roleNames);
            setSeatConfigurations(selectedMeetingRoom.seatingConfNames ?? []);
            setSeatConfigurationImageId(selectedMeetingRoom.seatingConfImageId || '')
            setSeatConfigurationDaysInAdvance(selectedMeetingRoom.seatingConfDays || 0)
        }

        function getRoleNamesOfSelectedMeetingRoom(selectedMeetingRoom: MeetingRoom) {
            return selectedMeetingRoom.roleIds?.map(getRoleNameById)
                .filter((roleName): roleName is string => Boolean(roleName)) ?? [];

            function getRoleNameById(id: string) {
                return availableRoles.find(roleObject => roleObject.roleId === id)?.roleName;
            }
        }

    }, [availableRoles, selectedMeetingRoom]);

    useEffect(function validateMeetingRoomName() {
        validateName();
    }, [name, validateName]);

    useEffect(function validateMeetingRoomCapacity() {
        validateCapacity()
    }, [capacity, validateCapacity]);


    const handleAddSeatingConfiguration = (): void => {
        setSelectedSeatConfiguration(undefined);
        setShowCreateSeatConfiguration(true);
    }

    const handleSelectConfiguration = (seatConfiguration: string) => {
        setSelectedSeatConfiguration(seatConfiguration);
    }

    const saveSelectedSeatConfiguration = (newSeatConfiguration: string) => {
        if (selectedMeetingRoom) {
            if (selectedSeatConfiguration) {
                const list = seatConfigurations.filter(seatConf => seatConf !== selectedSeatConfiguration);
                list.push(newSeatConfiguration);
                setSeatConfigurations(list);
                // update
                const updatedMeetingRoom: MeetingRoom = {
                    ...selectedMeetingRoom,
                    seatingConfNames: list
                };

                setSelectedSeatConfiguration(undefined);
                onSave(updatedMeetingRoom, true)
            } else {
                setSeatConfigurations([...seatConfigurations, newSeatConfiguration]);
                const updatedMeetingRoom: MeetingRoom = {
                    ...selectedMeetingRoom,
                    seatingConfNames: [...seatConfigurations, newSeatConfiguration]
                };
                onSave(updatedMeetingRoom, true)
            }
        }
    }


    const handleDeleteSeatingConfiguration = (): void => {
        if (selectedSeatConfiguration) {
            setSeatConfigurations(seatConfigurations.filter(seatConf => seatConf !== selectedSeatConfiguration));
            // update meetingRoom
            if (selectedMeetingRoom !== null) {
                const updatedMeetingRoom: MeetingRoom = {
                    ...selectedMeetingRoom,
                    seatingConfNames: seatConfigurations.filter(item => item !== selectedSeatConfiguration)
                };
                setShowDeleteSeatConfigurationDialog(false);
                setSelectedSeatConfiguration(undefined);
                onSave(updatedMeetingRoom, true)
            }
        }
    }

    const renderImage = (imageUrl: string) => (
        <img src={imageUrl} alt="Roomplan" style={{width: "70px", maxHeight: "70px"}}/>
    )

    const showErrorMessage = () => {
        setIsShowDeletionAlert(true)
        setTimeout(() => {
            setIsShowDeletionAlert(false)
        }, 4000)
    }

    return (
        <Dialog open={open} onClose={onClose} data-testid="edit-meeting-room">
            {isShowDeletionAlert && <Alert severity="error">nevymazalo sa to</Alert>}
            <DialogTitle>{t("configure_meeting_room")}</DialogTitle>
            <DialogContent>
                <Box marginBottom={2}>
                    <TextField
                        label={t("meeting_room_table_id_column")}
                        disabled={true}
                        value={selectedMeetingRoom ? selectedMeetingRoom.meetingRoomId : ""}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={!isNameValid}
                        helperText={!isNameValid && t("meeting_room_name_tooltip")}
                        type="text"
                        fullWidth
                        data-testid="edit-meeting-room-id"/>
                </Box>
                <Box marginBottom={2}>
                    <TextField
                        label={t("meeting_room_table_name_column")}
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={!isNameValid}
                        helperText={!isNameValid && t("meeting_room_name_tooltip")}
                        type="text"
                        fullWidth
                        data-testid="edit-meeting-room-name"/>
                </Box>
                <Box marginBottom={2}>
                    <TextField
                        label={t("meeting_room_capacity")}
                        value={capacity}
                        onChange={handleCapacityChange}
                        onBlur={handleCapacityBlur}
                        error={!isCapacityValid}
                        helperText={!isCapacityValid && t("meeting_room_capacity_tooltip")}
                        fullWidth
                        data-testid="edit-meeting-room-capacity"/>
                </Box>
                <Box marginBottom={2}>
                    <InputLabel>{t("meeting_room_manage_type")}</InputLabel>
                    <Select
                        MenuProps={{
                            disablePortal: true,
                        }}
                        value={type}
                        onChange={handleTypeChange}
                        onClick={(e) => e.stopPropagation()}
                        data-testid={"edit-meeting-room-type-selector"}
                        fullWidth
                    >
                        {Object.values(MeetingRoomType).map((roomType) => (
                            <MenuItem key={roomType} value={roomType}>
                                {t(getMeetingRoomTypeLabel(roomType))}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {buildingEquipment?.length > 0 && <Box marginBottom={2}>
                    <FormControl style={{width: "100%"}}>
                        <MultiSelectorDuplicities
                            options={buildingEquipment}
                            title={t("general_equipment-singular")}
                            selected={filteredEquipment}
                            onChange={(selection) => setFilteredEquipment(selection)}
                            getLabel={(equipment: MeetingRoomEquipment) => equipment.name}/>
                    </FormControl>
                </Box>}
                <Box marginBottom={2} data-testid={"edit-meeting-room-roles-selector"}>
                    <MultiSelectComboBox
                        options={availableRoles.map((role: any) => role.roleName)}
                        label={t("meeting_room_manage_rights")}
                        selectedValues={selectedRoles}
                        onChange={selectedValues => handleRolesChange(selectedValues)}
                        useDefaultStyles={false}/>
                </Box>
                <Box marginBottom={2}>
                    <TextField
                        style={{width: "100%"}}
                        label={t('meeting-room-seatconf-daysadvance')}
                        value={seatConfigurationDaysInAdvance}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSeatConfDays(+e.target.value)}
                        inputProps={{
                            "data-testid": "seatConfiguration-book-in-advance"
                        }}/>
                </Box>
                <Box marginBottom={2}>
                    <InputLabel style={{marginBottom: "10px"}}>{t("meeting-room-seatconf-image")} </InputLabel>
                    <input
                        accept="image/*"
                        style={{display: "none"}}
                        id="contained-image-file"
                        type="file"
                        onChange={async (event) => {
                            if (event.target.files) {
                                const image = event.target.files[0];
                                await handleChangeSeatConfImage(image);
                            }
                        }}
                    />

                    <Stack
                        direction="row"
                    >
                        {seatConfigurationImageUrl && (
                            <SeatConfigImagePreview imagePath={seatConfigurationImageUrl} showThumbnail={true}/>
                        )}
                        <Stack
                        >
                            <label htmlFor="contained-image-file">
                                <Tooltip title={t("meeting_room_seat_config_upload_image_tooltip")}>
                                    <StyledSeatConfigButton variant="contained" color="primary"
                                                            startIcon={<UploadFile/>} component="span"/>
                                </Tooltip>
                            </label>
                            {seatConfigurationImageUrl &&
                                <Tooltip title={t("meeting_room_seat_config_delete_image_tooltip")}>
                                    <StyledSeatConfigButton variant="contained" color="primary"
                                                            startIcon={<DeleteForeverIcon/>} onClick={e => {
                                        setSeatConfigurationImageId(undefined)
                                        setSeatConfigurationImageUrl(undefined)
                                    }}/>
                                </Tooltip>
                            }
                        </Stack>
                    </Stack>
                </Box>

                <Box>
                    {showDeleteSeatConfigurationDialog && selectedSeatConfiguration &&
                        <DeleteEntryConfirmationDialogComponent
                            isShowDeleteDialog={showDeleteSeatConfigurationDialog}
                            handleDelete={handleDeleteSeatingConfiguration}
                            setIsShowDeleteDialog={setShowDeleteSeatConfigurationDialog}
                        />}

                    {showCreateSeatConfiguration &&
                        <CreateOrUpdateMeetingRoomSeatConfiguration
                            selectedConfiguration={selectedSeatConfiguration}
                            seatConfigurations={seatConfigurations}
                            showCreateSeatConfiguration={showCreateSeatConfiguration}
                            setShowCreateSeatConfiguration={setShowCreateSeatConfiguration}
                            saveSelectedSeatConfiguration={saveSelectedSeatConfiguration}
                        />}

                    <Table style={{marginTop: "1rem", marginBottom: "1rem"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    data-testid={"edit-meeting-room-column-name"}
                                    style={{wordBreak: "break-word"}}
                                >
                                    {t("meeting_room_table_name_column")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {seatConfigurations?.length > 0 && seatConfigurations.sort().map((seatConfiguration) => (
                                <TableRow
                                    onClick={() => handleSelectConfiguration(seatConfiguration)}
                                    key={seatConfiguration}
                                    selected={selectedSeatConfiguration === seatConfiguration}>
                                    <TableCell>{seatConfiguration}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Divider/>
                    <DialogActions style={{display: "flex", justifyContent: "center", alignItems: "stretch"}}>
                        <Button
                            variant={"contained"} color={"primary"} startIcon={<Sit2GetherAddIcon/>}
                            onClick={handleAddSeatingConfiguration}
                            data-testid={"create-btn"}
                        >
                            {t("button_create_room-text")}
                        </Button>
                        <Button variant={"contained"} color={"primary"} startIcon={<Edit/>}
                                style={{wordBreak: "break-word"}}
                                disabled={!selectedSeatConfiguration}
                                onClick={() => setShowCreateSeatConfiguration(true)}
                                data-testid={"btn-edit-room"}
                        >
                            {t("inv_mngmnt_edit_button-text")}
                        </Button>
                        <Button variant={"contained"} color={"primary"} startIcon={<DeleteForeverIcon/>}
                                style={{wordBreak: "break-word"}}
                                disabled={!selectedSeatConfiguration}
                                onClick={() => setShowDeleteSeatConfigurationDialog(true)}
                                data-testid={"btn-delete-room"}
                        >
                            {t("delete")}
                        </Button>
                    </DialogActions>
                    <Divider/>
                </Box>
                <Box marginBottom={2}>
                    <FormControlLabel style={{paddingLeft: "7px", height: "59px"}}
                                      control={<Checkbox name="checkedC"
                                                         checked={isBookable}
                                                         onChange={handleIsBookableChange}/>}
                                      label={t("meetingroom-is-bookable-checkbox-label")}/>
                </Box>
            </DialogContent><DialogActions>
            <Button
                onClick={handleSave}
                color="primary"
                variant={"contained"}
                disabled={!isNameValid || !isCapacityValid}
                data-testid={"edit-meeting-room-save-button"}>
                {t('button_save')}
            </Button>
            <Button onClick={onClose}
                    color="primary"
                    variant={"contained"}>
                {t('rm_showUploadConfirmationDialog_cancel_button-text')}
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default EditMeetingRoom;
