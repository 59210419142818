import {HintInterface} from "../OfficeHintComponent";
import {Hint, MeetingRoom} from "../../../API";
import {HintSVGData} from "../../../Utils/svgPlans/hints";
import {useMemo} from "react";

const NO_HINT_OVERLAY_DATA: HintInterface[] = [];

export function useHintOverlayData(hints: Hint[], meetingRooms: MeetingRoom[], hintSVGData: HintSVGData[]) {
    return useMemo(() => {
        if (hints.length === 0 || hintSVGData.length === 0) {
            return NO_HINT_OVERLAY_DATA;
        }

        const idToHint = new Map(hints.map(hint => [hint.hintId, hint]));
        const idToMeetingRoom = new Map(meetingRooms.map(mr => [mr.meetingRoomId, mr]));

        const result: HintInterface[] = [];
        hintSVGData.forEach((hintSVGData) => {
            const id = hintSVGData.hintId;
            const dbHint = idToHint.get(id);
            if (dbHint === undefined) {
                return;
            }
            // TODO document that hints in meeting rooms should have a prefix of length 5 and then the meetingRoomId
            const meetingRoomId = dbHint.hintId.slice(5);
            const meetingRoom = idToMeetingRoom.get(meetingRoomId);

            const hintText = dbHint.text;
            const hintHasText = hintText.length > 0;
            const mrHasEquipment = (meetingRoom?.equipmentIds?.length ?? 0) > 0
            const mrHasSeatingConfigs = (meetingRoom?.seatingConfNames?.length ?? 0) > 0

            const isVisible = hintHasText || mrHasEquipment || mrHasSeatingConfigs;

            result.push(
                {
                    hintId: dbHint.hintId,
                    text: hintText,
                    hintIconDimension: hintSVGData.hintIconDimension,
                    hintIconPosition: hintSVGData.hintIconPosition,
                    isVisible: isVisible
                }
            );
        })
        return result;
    }, [hints, meetingRooms, hintSVGData])
}