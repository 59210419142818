import React from 'react';
import SVG from 'react-inlinesvg';
import {SeatBookings} from "../../types/SeatBookinType";
import {MeetingRoomBookings} from '../../types/MeetingRoomBookingType';
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {BookingP, MeetingRoomBookingP} from "../../types/PermissionHandling";
import {useNeighborhoodList} from "../../hooks/useNeighborhoodList";
import {useIdsOfAdministratedNeighborhoodsByRoomId} from "../../hooks/useHasAccessByNeighborhoodAndRoomId";
import {useSplitSeatAndMeetingRoomBookings} from "../../hooks/bookings/useSplitSeatAndMeetingRoomBookings";
import {useHintsOfBuildingByBuildingId} from "../../hooks/hint/useHintsOfBuildingByBuildingId";
import {SeatOverlay} from "./planOverlayComponents/SeatOverlay";
import {MeetingRoomOverlay} from "./planOverlayComponents/MeetingRoomOverlay";
import {HintOverlay} from "./planOverlayComponents/HintOverlay";
import {useIntermediateRoomPlanXMLDocument} from "./hooks/useIntermediateRoomPlanXMLDocument";
import {useFinalRoomPlanXMLDocument} from "./hooks/useFinalRoomPlanXMLDocument";
import {useIsUserOrgUnitAdminOfRoom} from "../../hooks/permissions/useIsUserOrgUnitAdminOfRoom";
import {useIsUserAdminOfBuilding} from "../../hooks/permissions/useIsUserAdminOfBuilding";
import {useMeetingRoomListSingleRoom} from "../../hooks/meetingRooms/useMeetingRoomListSingleRoom";
import {useSeatOverlayData} from "./hooks/useSeatOverlayData";
import {useMeetingRoomOverlayData} from "./hooks/useMeetingRoomOverlayData";
import {useHintOverlayData} from "./hooks/useHintOverlayData";
import {useSeatConfigMapByRoom} from "./hooks/useSeatConfigmapByRoom";
import {useJoinedDBAndSVGMeetingRoomData} from "./hooks/useJoinedDBAndSVGMeetingRoomData";


interface Props {
    onSeatClick: (seatBookings: SeatBookings) => void
    onMeetingRoomClick: (meetingRoomBookings: MeetingRoomBookings) => void
    bookingList: (BookingP | MeetingRoomBookingP)[] | []
    roomId: string
    roomPlanString: string
    isTimeBookingActive: boolean
    buildingId: string
}

const TwoGetherSVGLoader: React.FC<Props> = (props) => {
    const {
        onSeatClick, onMeetingRoomClick,
        bookingList,
        roomId,
        roomPlanString,
        isTimeBookingActive,
        buildingId
    } = props;

    const {seatBookings, meetingRoomBookings} = useSplitSeatAndMeetingRoomBookings(bookingList);
    const {rerenderSeatConfigsTrigger} = useMainApplicationContext();
    const {seatIdToSeatConfig} = useSeatConfigMapByRoom(roomId, rerenderSeatConfigsTrigger);
    const {hints} = useHintsOfBuildingByBuildingId(buildingId);
    const [meetingRoomList] = useMeetingRoomListSingleRoom(roomId);
    const {isUserOrgUnitAdminOfRoom} = useIsUserOrgUnitAdminOfRoom(roomId);
    const [neighborhoodList] = useNeighborhoodList(roomId ?? "");
    const {userIsBuildingAdmin} = useIsUserAdminOfBuilding(buildingId);

    const {neighborhoodIdsUserIsAdmin} = useIdsOfAdministratedNeighborhoodsByRoomId(roomId);

    const {
        intermediateXMLDocument,
        viewBoxDimensions,
        seatSVGData,
        meetingRoomSVGData,
        hintSVGData,
    } = useIntermediateRoomPlanXMLDocument(roomPlanString);

    const seatOverlayData = useSeatOverlayData(roomId, seatSVGData, seatBookings);
    const meetingRoomOverlayData: MeetingRoomBookings[] = useMeetingRoomOverlayData(roomId, meetingRoomBookings, meetingRoomList, meetingRoomSVGData);
    const hintOverlayData = useHintOverlayData(hints, meetingRoomList, hintSVGData);

    const meetingRoomSVGUpdateData = useJoinedDBAndSVGMeetingRoomData(meetingRoomList, meetingRoomSVGData);

    const {finalXMLDocument} = useFinalRoomPlanXMLDocument(intermediateXMLDocument, meetingRoomSVGUpdateData, neighborhoodList);

    const style = {width: "100vw", height: "100vh"}
    const viewBox = `0 0 ${viewBoxDimensions.width} ${viewBoxDimensions.height}`;

    return (
        <div>
            {viewBoxDimensions.width !== "" && viewBoxDimensions.height !== "" && finalXMLDocument !== null &&
                <svg style={style} viewBox={viewBox}>
                    <SVG src={new XMLSerializer().serializeToString(finalXMLDocument)}/>
                    <SeatOverlay seatBookings={seatOverlayData} onSeatClick={onSeatClick}
                                 userIsBuildingAdmin={userIsBuildingAdmin} seatIdToConfig={seatIdToSeatConfig}
                                 isTimeBookingActive={isTimeBookingActive}
                                 rerenderSeatConfigsTrigger={rerenderSeatConfigsTrigger}
                                 userIsOrgUnitAdminOfRoom={isUserOrgUnitAdminOfRoom}
                                 neighborhoodAdminIds={neighborhoodIdsUserIsAdmin}/>
                    <MeetingRoomOverlay onMeetingRoomClick={onMeetingRoomClick}
                                        meetingRoomBookings={meetingRoomOverlayData}
                                        meetingRooms={meetingRoomList} userIsBuildingAdmin={userIsBuildingAdmin}
                                        isTimeBookingActive={isTimeBookingActive}
                                        userIsOrgUnitAdminOfRoom={isUserOrgUnitAdminOfRoom}
                                        neighborhoodAdminIds={neighborhoodIdsUserIsAdmin}/>
                    <HintOverlay hintArray={hintOverlayData}/>
                </svg>}
        </div>
    )
}

export default TwoGetherSVGLoader;
