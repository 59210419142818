import {Neighborhood} from "../API";
import {User} from "../services/UserClient";

export function hasUserAccessToNeighborhood(neighborhood: Neighborhood, user: User) {
    const rolesOfCurrentUser = user?.admin2GetherRoles;
    const rolesOfNeighborhood = [...neighborhood?.roleIds || [], ...neighborhood?.adminRoleIds || []];
    let hasAccess = false;
    if (!neighborhood?.restricted) {
        hasAccess = true;
    } else if (neighborhood.restricted) {
        hasAccess = rolesOfNeighborhood?.some(role => rolesOfCurrentUser.includes(role)) ?? false;
    }

    return hasAccess;
}