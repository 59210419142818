import {useErrorContext} from "./useErrorContext";
import {useCallback} from "react";
import {MeetingRoom} from "../API";

export interface StoredSeatConfiguration {
    name: string;
    meetingRoomId: string;
    imageUrl?: string | null;
}

export const useMeetingRoomSeatConfigurations = () => {
    const {reportError} = useErrorContext()

    const getSeatConfigurations = useCallback(async (meetingRoom: MeetingRoom): Promise<StoredSeatConfiguration[]> => {
        if (!meetingRoom) return [];
        try {
            return meetingRoom.seatingConfNames?.map((name) => ({
                name: name,
                meetingRoomId: meetingRoom.meetingRoomId,
                imageUrl: meetingRoom.seatingConfImageUrl,
            })) ?? [];

        } catch (error) {
            reportError(error as Error, "Error retrieving seating configurations", "useMeetingRoomSeatConfigurations");
            return [];
        }
    }, [reportError]);

    return {getSeatConfigurations};
}