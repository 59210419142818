import {createContext} from "react";
import {RouterContextTypes} from "../types/RouterContextTypes";


const RouterContext = createContext<RouterContextTypes>({
    roomPlanKey: '',
    seatId: '',
    shouldTrigger: false
})


export default RouterContext
