import {Box, Button, createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles<Theme>(() => createStyles({
    btnSelected: {
        "&&": {
            backgroundColor: "#eae0dd",
            color: "#3e3938"
        }
    }
}));

interface Props {
    newOwner: string
    setNewOwner: (value: string) => void
}


const SeatConfigOwnerComponent: React.FC<Props> = (props) => {

    const {t} = useTranslation();

    const {newOwner, setNewOwner} = props
    const ownerIsDefined = newOwner.trim() !== "";
    const classes = useStyles();

    const [ownerInput, setOwnerInput] = useState("")

    return (
        <Box style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "0.5rem 0.5rem 0rem 0.5rem",
            alignItems: "center",
        }}>
            <div style={{width: "40%"}}>
                <span>{t("owner-column")}</span><br/>
                <span style={ownerIsDefined ? {} : {color: "red"}} data-testid={"owner-label"}>
                    {ownerIsDefined ? " " + newOwner : t("no_owner")}
                </span>
            </div>
            <Box style={{width: "45%", height: "80%", paddingRight: "1rem"}}>
                <TextField
                    size={"small"}
                    className={classes.txtRoomName}
                    style={{width: "100%", height: "80%"}}
                    label={t("owner-column")}
                    value={ownerInput}
                    onChange={(e) => {
                        e.preventDefault();
                        setOwnerInput(e.target.value)
                    }}
                    inputProps={{"data-testid": "owner-input"}}
                    variant={"outlined"}
                />
            </Box>
            <Button
                onClick={() => setNewOwner(ownerInput.trim())}
                color={"primary"}
                variant={"contained"}
                data-testid={"owner-apply"}
            >
                {t("apply")}
            </Button>
        </Box>
    )
}

export default SeatConfigOwnerComponent
