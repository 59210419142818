import {useMemo} from "react";
import {extractSeatData, removePartsOfSeatElements} from "../../../Utils/svgPlans/seats";
import {
    extractMeetingRoomData,
    MeetingRoomSVGData,
    removePartsOfMeetingRoomsFromXMLDocument
} from "../../../Utils/svgPlans/meetingRooms";
import {extractHintSVGData, HintSVGData, removeHintElementsFromXMLDocument} from "../../../Utils/svgPlans/hints";
import {removeNeighborhoodTspans} from "../../../Utils/svgPlans/neighborhoods";
import {getSVGViewBoxDimensions} from "../../../Utils/svgPlans/svgPlan";
import {DimensionsStr} from "../../../types/PositioningAndDimensionTypes";
import {SeatInterface} from "../SeatComponent";

interface UseAdjustedRoomPlanXMLDocumentResult {
    intermediateXMLDocument: XMLDocument | null,
    viewBoxDimensions: DimensionsStr,
    seatSVGData: SeatInterface[],
    meetingRoomSVGData: MeetingRoomSVGData[],
    hintSVGData: HintSVGData[]
}

const DEFAULT_VIEW_BOX_DIMENSIONS: DimensionsStr = {width: "", height: ""};
const NO_SEAT_SVG_DATA: SeatInterface[] = []
const NO_MEETING_ROOM_SVG_DATA: MeetingRoomSVGData[] = []
const NO_HINT_SVG_DATA: HintSVGData[] = []

export function useIntermediateRoomPlanXMLDocument(roomPlanString: string): UseAdjustedRoomPlanXMLDocumentResult {
    return useMemo(() => {
        if (roomPlanString.length === 0) {
            return {
                intermediateXMLDocument: null,
                viewBoxDimensions: DEFAULT_VIEW_BOX_DIMENSIONS,
                seatSVGData: NO_SEAT_SVG_DATA,
                meetingRoomSVGData: NO_MEETING_ROOM_SVG_DATA,
                hintSVGData: NO_HINT_SVG_DATA,
            };
        }
        const doc = new DOMParser().parseFromString(roomPlanString, "image/svg+xml");
        const errorNode = doc.querySelector("parseerror");
        if (errorNode) {
            return {
                intermediateXMLDocument: null,
                viewBoxDimensions: DEFAULT_VIEW_BOX_DIMENSIONS,
                seatSVGData: NO_SEAT_SVG_DATA,
                meetingRoomSVGData: NO_MEETING_ROOM_SVG_DATA,
                hintSVGData: NO_HINT_SVG_DATA,
            };
        }

        // get data from svg (ids, positions, ...)
        const seatData = extractSeatData(doc);
        const meetingRoomData = extractMeetingRoomData(doc);
        const hintData = extractHintSVGData(doc);

        // remove parts from svg
        removePartsOfSeatElements(doc);
        removePartsOfMeetingRoomsFromXMLDocument(doc);
        removeHintElementsFromXMLDocument(doc);
        removeNeighborhoodTspans(doc);

        return {
            intermediateXMLDocument: doc,
            viewBoxDimensions: getSVGViewBoxDimensions(doc),
            seatSVGData: seatData,
            meetingRoomSVGData: meetingRoomData,
            hintSVGData: hintData,
        };

    }, [roomPlanString]);
}