import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    NativeSelect,
    Paper,
    TextField,
    Tooltip
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {createSecureRoom, updateHint, updateSeatConfig, updateSecureRoom,} from "../graphql/mutations";
import {roomByBuildingId,} from "../graphql/queries"
import {maternaTheme as materna_theme} from "../styles/materna_theme";
import {Building, Hint, Inventory, Room, SeatConfig} from "../API";
import {IOrgUnit} from "../hooks/useOrgunit";
import {useInventoryList} from "../hooks/useInventoryList";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useHintList} from "../hooks/useHintList";
import {useSeatConfigsOfRoom} from "../hooks/useSeatConfigsOfRoom";

const HANDLE_UPDATE_ROOM = "updateRoom";

interface Props {
    selectedBuilding: Building
    showCreateRoom: boolean
    setShowCreateRoom: (value: boolean) => void
    roomToEdit: Room | undefined;
    setIsShowAlerts: (a: any) => void
    handleUpdateOrgUnitIdForBookings: (roomId: string, newOrgUnitId: string) => void
    isAdmin: boolean
}

const CreateRoomComponent: React.FC<Props> = (props) => {
    const {
        selectedBuilding,
        showCreateRoom,
        setShowCreateRoom,
        roomToEdit,
        setIsShowAlerts,
        handleUpdateOrgUnitIdForBookings,
    } = props;

    const {
        adminOrgUnitList
    } = useMainApplicationContext()

    const [roomName, setRoomName] = useState("")
    const [scaleFactorValue, setScaleFactorValue] = useState<number>(100)
    const [selectedOrgUnitId, setSelectedOrgUnitId] = useState<string>(adminOrgUnitList[0]?.orgId);
    const [urlKey, setUrlKey] = useState<string>('');
    const [showUrlKeyUniqueError, setShowUrlKeyUniqueError] = useState<boolean>(false);
    const {reportError} = useErrorContext()

    const nameInputRef = useRef<HTMLInputElement>(null);
    const [updateSeatConfigMutation] = useMutation(gql(updateSeatConfig))
    const [updateHintMutation] = useMutation(gql(updateHint))

    const {inventories} = useInventoryList()
    const {fetchAllSeatConfigs} = useSeatConfigsOfRoom();
    const hintList = useHintList(props.roomToEdit?.roomId ?? "", selectedBuilding?.buildingId)

    const useStyles = makeStyles({
        btnEnabled: {
            color: "green"
        },
        btnDisabled: {
            color: "disabled"
        },
        txtRoomName: {
            [materna_theme.breakpoints.up('xs')]: {
                width: "40%",
            },
            [materna_theme.breakpoints.up('lg')]: {
                width: "40%",
            },
            [materna_theme.breakpoints.up('xl')]: {
                width: "65%",
            },
        },
        scaleFactorInput: {
            width: "6rem",
            marginLeft: "10px"
        }
    });
    const classes = useStyles();

    const [createRoomMutation] = useMutation(gql(createSecureRoom))
    const [updateRoomMutation] = useMutation(gql(updateSecureRoom))
    const {refetch} = useQuery(gql(roomByBuildingId))

    const urlKeyRegex = /^[a-zA-Z0-9]*$/;
    // these 2 messages are connected with BE in lambda if it will be changed it needs to be changes also in lambda
    const DUPLICATE_URL_KEY_MESSAGE = 'DUPLICATE_URL_KEY';
    const INVALID_URL_KEY_MESSAGE = 'INVALID_URL_KEY';

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setRoomName(event.target.value)
    }
    const handleScaleFactor = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!isNaN(+event.target.value) && (+event.target.value) <= 1000) {
            setScaleFactorValue(+event.target.value)
        }
    }

    const handleUrlKey = (event: ChangeEvent<HTMLInputElement>) => {
        setShowUrlKeyUniqueError(event.target.value?.length > 0 && !urlKeyRegex.test(event.target.value))
        setUrlKey(event.target.value)
    }
    const handleCreateRoom = () => {
        createRoomMutation({
            variables: {
                input: {
                    name: roomName.trim(),
                    buildingId: selectedBuilding.buildingId,
                    orgUnitId: selectedOrgUnitId,   // TODO remove org unit
                    roomPlanScaleFactor: scaleFactorValue ? scaleFactorValue : 100,     // TODO which values are valid?
                    urlKey: urlKey
                }
            }
        })
            .then(() => setShowCreateRoom(false))
            .catch((err) => {
                if (err.message === DUPLICATE_URL_KEY_MESSAGE || err.message === INVALID_URL_KEY_MESSAGE) {
                    setShowUrlKeyUniqueError(true);
                } else {
                    reportError(err, "", "CreateRoomComponent handleCreateRoom");
                }
            })
    }

    const {t} = useTranslation();

//ToDo: Move to Lambda?
    function checkInventory(orgUnitId: string, inv: string) {
        let oldInventory = inventories.find(_inv => _inv.inventoryId === inv)
        if (oldInventory !== undefined) {
            return inventories.find((inventory: Inventory) => {
                return inventory.orgUnitId === orgUnitId && inventory.name === oldInventory?.name
            })
        }
    }

//ToDo: Move to Lambda?
    function generateNewInventoryList(seatConfig: SeatConfig, newOrgUnitId: string, seatInventory: string[]) {
        if (seatConfig.inventory) {
            seatConfig.inventory.forEach(inv => {
                let maybeNewInventory = checkInventory(newOrgUnitId, inv)
                if (maybeNewInventory) {
                    seatInventory.push(maybeNewInventory.inventoryId)
                }
            })
        }
    }

//ToDo: Move to Lambda?
    async function migrateSeatInventory(newOrgUnitId: string) {
        if (!props?.roomToEdit?.roomId) {
            return;
        }
        const seatConfigs = await fetchAllSeatConfigs(props.roomToEdit.roomId);
        seatConfigs.forEach((seatConfig: SeatConfig) => {
            let seatInventory: string[] = []
            generateNewInventoryList(seatConfig, newOrgUnitId, seatInventory);
            updateSeatConfigMutation({
                variables: {
                    input: {
                        seatName: seatConfig.seatName,
                        roomId: seatConfig.roomId,
                        orgUnitAdmin: "Admin-" + newOrgUnitId,
                        isSeatHeightAdjustable: seatConfig.isSeatHeightAdjustable,
                        owner: seatConfig.owner,
                        isOwnerRegistered: seatConfig.isOwnerRegistered,
                        inventory: seatInventory,
                        isBookable: seatConfig.isBookable
                    }
                }
            }).catch((err) => reportError(err, "", "CreateRoomComponent migrateSeatInventory")
            )
        })

    }

    function updateHintsOrgUnitId(newOrgUnitId: string) {
        if (hintList) {
            hintList.forEach((hint: Hint) => {
                updateHintMutation({
                    variables: {
                        input: {
                            hintId: hint.hintId,
                            roomId: hint.roomId,
                            orgUnitAdmin: "Admin-" + newOrgUnitId,
                            orgUnitId: newOrgUnitId,
                        },

                    }
                }).catch((err) => console.error("CreateRoomComponent updateHintsOrgUnitId " + JSON.stringify(err)))
            })
        }
    }

    const handleUpdateRoom = () => {
        const orgUnitIdChanged = selectedOrgUnitId !== roomToEdit!.orgUnitId
        if (orgUnitIdChanged && selectedOrgUnitId) {
            migrateSeatInventory(selectedOrgUnitId)
            updateHintsOrgUnitId(selectedOrgUnitId)
        }
        //Update Seat Inventory
        updateRoomMutation({
            variables: {
                input: {
                    name: roomName.trim(),
                    roomId: roomToEdit!.roomId,
                    orgUnitId: selectedOrgUnitId,
                    roomPlanScaleFactor: scaleFactorValue ? scaleFactorValue : 100,
                    isDefault: orgUnitIdChanged ? false : roomToEdit!.isDefault,
                    urlKey: urlKey
                },
                updateType: HANDLE_UPDATE_ROOM
            }
        })
            .then(() => {
                if (orgUnitIdChanged)
                    handleUpdateOrgUnitIdForBookings(roomToEdit!.roomId, selectedOrgUnitId!)
            })
            .then(() => setShowCreateRoom(false))
            .catch((err) => {
                if (err.message === DUPLICATE_URL_KEY_MESSAGE || err.message === INVALID_URL_KEY_MESSAGE) {
                    setShowUrlKeyUniqueError(true);
                } else {
                    reportError(err, "", "CreateRoomComponent handleUpdateRoom ");
                }
            })
    }

    const handleSave = async () => {
        const isNameConflicting = (await refetch({
            buildingId: selectedBuilding.buildingId
        })).data.roomByBuildingId.items
            .filter((room: Room) => room?.roomId !== roomToEdit?.roomId)
            .some((room: Room) => room?.nameLowerCased === roomName!.trim().toLocaleLowerCase())
        if (isNameConflicting) {
            setIsShowAlerts(
                {success: false, error: false, error_create_room: true})
            setTimeout(() => {
                setIsShowAlerts({success: false, error: false, error_create_room: false})
            }, 4000)
            return
        }
        if (!!roomToEdit) {
            handleUpdateRoom()
        } else {
            handleCreateRoom()
        }
    }

    useEffect(() => {
        if (roomToEdit) {
            setRoomName(roomToEdit.name)
            setUrlKey(roomToEdit.urlKey ?? "");
            setSelectedOrgUnitId(roomToEdit.orgUnitId!)
        }
    }, [roomToEdit])

    const isRoomNameValid = useMemo(() => {
        return roomName.trim().length > 0;
    }, [roomName])

    const isSaveButtonEnabled = isRoomNameValid && selectedOrgUnitId !== ""

    useEffect(() => {
        if (nameInputRef?.current !== null) {
            nameInputRef.current.focus();
        }
    }, [])

    return (
        <Paper style={{
            marginTop: "0.625rem",
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }} data-testid={"createRoomComponent"}>
            <Box style={{
                paddingTop: "1rem",
                display: "flex",
                justifyContent: "flex-start",
                width: "74%"
            }}>
                <TextField
                    className={classes.txtRoomName}
                    style={{marginRight: "10px", width: "60%"}}
                    label={t('room-name')}
                    value={roomName}
                    onChange={handleInputChange}
                    inputRef={nameInputRef}
                    inputProps={{
                        "data-testid": "roomName-text"
                    }}/>
                {showCreateRoom &&
                    <> <FormControl style={{width: "39%"}}>
                        <InputLabel shrink htmlFor="org-unit">
                            {t("daily_bookings_table_orgunit_column")}
                        </InputLabel>
                        <NativeSelect
                            value={selectedOrgUnitId}
                            onChange={(e) => setSelectedOrgUnitId(e.target.value)}
                            data-testid={"select-org-unit-on-edit"}>

                            {adminOrgUnitList.map((org: IOrgUnit) => {
                                    return <option
                                        key={org.orgName}
                                        value={org.orgId}
                                        data-testid={"org-dropdown-on-edit-option-" + org.orgName}>
                                        {org.orgName}
                                    </option>
                                }
                            )}
                        </NativeSelect>
                    </FormControl>
                        <TextField
                            className={classes.scaleFactorInput}
                            label={t("scaleFactor-column")}
                            value={scaleFactorValue}
                            onChange={handleScaleFactor}
                            inputProps={{"data-testid": "scale-factor-input"}}
                        />
                        <TextField
                            className={classes.scaleFactorInput}
                            style={{minWidth: "20%"}}
                            label={t("room_url-key-active")}
                            value={urlKey}
                            onChange={handleUrlKey}
                            error={showUrlKeyUniqueError}
                            helperText={showUrlKeyUniqueError ? t('room_plan_not_unique') : ''}
                            data-testid={"url-key-input"}/>
                    </>
                }
            </Box>

            <Box style={{
                paddingTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
                width: "25%"
            }}>
                <Tooltip title={t('confirm_dialog_ok_button-text')}>
                    <span>
                    <IconButton onClick={handleSave} disabled={!isSaveButtonEnabled} data-testid={"save-btn"}>
                    <SaveIcon className={isSaveButtonEnabled ? classes.btnEnabled : classes.btnDisabled}
                              style={{cursor: "pointer"}} fontSize="large"/>
                    </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={t('cancel')}>
                    <IconButton onClick={() => setShowCreateRoom(false)} data-testid={"cancel-btn"}>
                        <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
                    </IconButton>
                </Tooltip>

            </Box>
        </Paper>
    )
}
export default CreateRoomComponent
