import {updateTspanContent} from "./svgPlan";
import {Dimensions, Position} from "../../types/PositioningAndDimensionTypes";
import {MeetingRoom} from "../../API";
import {MeetingRoomInfoWithCapacity} from "../../types/MeetingRoomBookingListItemType";

export interface MeetingRoomSVGUpdateData {
    meetingRoomId: string
    meetingRoomName: string
    capacity: number
}

export interface MeetingRoomSVGData {
    meetingRoomId: string
    neighborhoodId: string
    roomCap: number
    meetingRoomGroupPosition: Position
    meetingRoomPosition: Position
    meetingRoomDimension: Dimensions
    meetingRoomShape: string
    meetingRoomIconPosition: Position
    meetingRoomIconDimension: Dimensions
}

function getMeetingRoomSVGGElements(xmlDocument: XMLDocument) {
    const meetingRoomGroupElement = xmlDocument.getElementById("meetingRooms");
    return meetingRoomGroupElement?.querySelectorAll("g") ?? [];
}

export function extractMeetingRoomData(xmlDocument: XMLDocument): MeetingRoomSVGData[] {
    const meetRoomGElements = getMeetingRoomSVGGElements(xmlDocument);
    const meetingRooms: MeetingRoomSVGData[] = [];
    meetRoomGElements.forEach(element => {
        let roomGroup = element;
        let room = roomGroup.querySelector("path");
        let roomCapElem = roomGroup.querySelector(".roomCapacity");
        let roomIcon = roomGroup.querySelector(".icon");

        const neighborhoodId = roomGroup.getAttribute("neighborhoodId") ?? "";
        const meetingRoomCapacity = Number(roomCapElem?.getAttribute("roomCap")) || 0; // NaN -> 0

        meetingRooms.push({
            neighborhoodId: neighborhoodId,
            meetingRoomId: roomGroup.getAttribute("id") ?? "0",
            roomCap: meetingRoomCapacity,
            meetingRoomGroupPosition: {
                x: parseInt(roomGroup?.getAttribute("x") ?? "0"),
                y: parseInt(roomGroup?.getAttribute("y") ?? "0"),
                transform: roomGroup?.getAttribute("transform") ?? undefined
            },
            meetingRoomPosition: {
                x: parseInt(room?.getAttribute("x") ?? "0"),
                y: parseInt(room?.getAttribute("y") ?? "0"),
                transform: room?.getAttribute("transform") ?? undefined
            },
            meetingRoomDimension: {
                width: parseInt(room?.getAttribute("width") ?? "0"),
                height: parseInt(room?.getAttribute("height") ?? "0"),
            },
            meetingRoomShape: room?.getAttribute("d") ?? "0",
            meetingRoomIconPosition: {
                x: parseInt(roomIcon?.getAttribute("x") ?? "0"),
                y: parseInt(roomIcon?.getAttribute("y") ?? "0"),
                transform: roomIcon?.getAttribute("transform") ?? undefined
            },
            meetingRoomIconDimension: {
                width: parseInt(roomIcon?.getAttribute("width") ?? "0"),
                height: parseInt(roomIcon?.getAttribute("height") ?? "0"),
            }
        });
    })

    return meetingRooms;
}

export function removePartsOfMeetingRoomsFromXMLDocument(xmlDocument: XMLDocument) {
    const meetRoomGElements = getMeetingRoomSVGGElements(xmlDocument);
    meetRoomGElements.forEach(element => {
        element.querySelector(".bookerName")?.remove();
        element.querySelector(".icon")?.remove();
    });
}


export function updateSVGMeetingRooms(xmlDocument: XMLDocument, meetingRoomsToUpdate: MeetingRoomSVGUpdateData[], capacityLabel: string) {
    const meetingRoomGroupElement = xmlDocument.getElementById("meetingRooms");

    if (meetingRoomGroupElement === null) {
        return;
    }

    meetingRoomsToUpdate.forEach((data) => {
        const meetingRoomSVGGElement = meetingRoomGroupElement.querySelector(`g[id="${data.meetingRoomId}"]`);
        if (meetingRoomSVGGElement === null) {
            return;
        }
        updateTspanContent(meetingRoomSVGGElement, ".roomCapacity", data.capacity.toString(), capacityLabel)
        updateTspanContent(meetingRoomSVGGElement, ".roomName", data.meetingRoomName)
    });
}

export function meetingRoomIdComparator(a: MeetingRoom, b: MeetingRoom) {
    return a.meetingRoomId.localeCompare(b.meetingRoomId, 'en', {numeric: true});
}

export function toMeetingRoomToInfoWithCapacity(meetingRoom: MeetingRoom): MeetingRoomInfoWithCapacity {
    return (
        {
            meetingRoomId: meetingRoom.meetingRoomId,
            type: meetingRoom.type,
            name: meetingRoom.name,
            nameLowerCased: meetingRoom.name.toLowerCase(),
            capacity: meetingRoom.capacity,
            roomId: meetingRoom.roomId,
            hintId: meetingRoom.hintId
        }
    );
}