import React, {useCallback, useEffect, useState} from "react";
import {Neighborhood} from "../../API";

interface Props {
    selectedNeighborhood: Neighborhood | undefined
    isRoomDropdownFocussed: boolean
    isBuildingDropdownFocused: boolean
    zoomContext: any
    roomPlanContainerRef: any
    triggerNeighborhoodUpdate: boolean
    roomFinderSelectedRoom: string | undefined
    triggerRoomFinderSelectedRoomUpdate: boolean
}

const RoomPlanPanningComponent: React.FC<Props> = (props) => {

    interface PanTarget {
        positionX: number | undefined
        positionY: number | undefined
    }

    const roomFinderSelectedRoom = props.roomFinderSelectedRoom
    const triggerRoomFinderSelectedRoomUpdate = props.triggerRoomFinderSelectedRoomUpdate
    const roomPlanContainerRef = props.roomPlanContainerRef;
    const selectedNeighborhood = props.selectedNeighborhood;
    const zoomContext = props.zoomContext;
    const triggerNeighborhoodUpdate = props.triggerNeighborhoodUpdate;
    const [panTarget, setPanTarget] = useState<PanTarget>({positionX: undefined, positionY: undefined})

    const panStart = (event: any) => {
        let roomPlanContainerWidth = roomPlanContainerRef.current ? roomPlanContainerRef.current.offsetWidth : 0;
        let roomPlanContainerHeight = roomPlanContainerRef.current ? roomPlanContainerRef.current.offsetHeight : 0;

        const isNotAllowedToMove = event.repeat || props.isRoomDropdownFocussed || props.isBuildingDropdownFocused;
        if (isNotAllowedToMove)
            return

        switch (event.key) {
            case "ArrowUp":
                setPanTarget((current) => {
                    return {
                        positionX: (current.positionX !== undefined ? current.positionX : zoomContext.current.instance.transformState.positionX),
                        positionY: zoomContext.current.instance.bounds.maxPositionY + roomPlanContainerHeight
                    }
                })
                break;
            case "ArrowDown":
                setPanTarget((current) => {
                    return {
                        positionX: (current.positionX !== undefined ? current.positionX : zoomContext.current.instance.transformState.positionX),
                        positionY: zoomContext.current.instance.bounds.minPositionY - roomPlanContainerHeight
                    }
                })
                break;
            case "ArrowLeft":
                setPanTarget((current) => {
                    return {
                        positionX: zoomContext.current.instance.bounds.maxPositionX + roomPlanContainerWidth,
                        positionY: (current.positionY !== undefined ? current.positionY : zoomContext.current.instance.transformState.positionY)
                    }
                })
                break;
            case "ArrowRight":
                setPanTarget((current) => {
                    return {
                        positionX: zoomContext.current.instance.bounds.minPositionX - roomPlanContainerWidth,
                        positionY: (current.positionY !== undefined ? current.positionY : zoomContext.current.instance.transformState.positionY)
                    }
                })
                break;
        }
    }

    const panStop = (event: any) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            setPanTarget((current) => {
                return {positionX: current.positionX, positionY: zoomContext.current.instance.transformState.positionY}
            })
        } else if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
            setPanTarget((current) => {
                return {positionX: zoomContext.current.instance.transformState.positionX, positionY: current.positionY}
            })
        }
    }

    const calcPanningAnimationSpeed = useCallback((targetX: number, targetY: number) => {
        const deltaX = targetX - zoomContext.current.instance.transformState.positionX
        const deltaY = targetY - zoomContext.current.instance.transformState.positionY
        const distance = Math.hypot(deltaX, deltaY)
        return Math.max(1, distance)
    }, [zoomContext])

    useEffect(() => {
        if (panTarget.positionX !== undefined && panTarget.positionY !== undefined) {
            zoomContext.current?.setTransform(panTarget.positionX, panTarget.positionY, zoomContext.current.instance.transformState.scale,
                calcPanningAnimationSpeed(panTarget.positionX, panTarget.positionY));
        }
    }, [calcPanningAnimationSpeed, panTarget, zoomContext])

    useEffect(() => {
        //Zooms to an svg element based on its id, defined in the svg file
        if (!selectedNeighborhood && !roomFinderSelectedRoom) {
            zoomContext.current?.resetTransform();
        } else {
            if (roomFinderSelectedRoom) {
                zoomContext.current?.zoomToElement(roomFinderSelectedRoom);
            } else {
                zoomContext.current?.zoomToElement(selectedNeighborhood!.neighborhoodId);
            }
        }
    }, [selectedNeighborhood, roomFinderSelectedRoom, triggerRoomFinderSelectedRoomUpdate, triggerNeighborhoodUpdate])

    useEffect(() => {
        document.addEventListener('keydown', panStart)
        document.addEventListener('keyup', panStop)

        return function cleanup() {
            document.removeEventListener('keydown', panStart);
            document.removeEventListener('keyup', panStop);
        }
    })

    return null;
}

export default RoomPlanPanningComponent;
