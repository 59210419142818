/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPresignedRoomplanUrl = /* GraphQL */ `
  query GetPresignedRoomplanUrl(
    $writeAccess: Boolean!
    $roomId: String!
    $roomplanId: String!
  ) {
    getPresignedRoomplanUrl(
      writeAccess: $writeAccess
      roomId: $roomId
      roomplanId: $roomplanId
    )
  }
`;
export const getPresignedImageUrl = /* GraphQL */ `
  query GetPresignedImageUrl(
    $writeAccess: Boolean!
    $roomId: String!
    $imageId: String!
  ) {
    getPresignedImageUrl(
      writeAccess: $writeAccess
      roomId: $roomId
      imageId: $imageId
    )
  }
`;
export const getRoomPlanExecutionStructure = /* GraphQL */ `
  query GetRoomPlanExecutionStructure($items: ActionQueryItemsInput) {
    getRoomPlanExecutionStructure(items: $items)
  }
`;
export const getRooms = /* GraphQL */ `
  query GetRooms {
    getRooms {
      items {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        urlKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRooms = /* GraphQL */ `
  query GetMeetingRooms($roomIds: [String]) {
    getMeetingRooms(roomIds: $roomIds) {
      items {
        meetingRoomId
        roomId
        name
        nameLowerCased
        hintId
        type
        capacity
        roleIds
        neighborhoodId
        equipmentIds
        isBookable
        seatingConfImageId
        seatingConfImageUrl
        seatingConfNames
        seatingConfDays
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoomsByBuildingId = /* GraphQL */ `
  query GetMeetingRoomsByBuildingId($buildingId: ID!) {
    getMeetingRoomsByBuildingId(buildingId: $buildingId) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      seatingConfImageId
      seatingConfImageUrl
      seatingConfNames
      seatingConfDays
      createdAt
      updatedAt
    }
  }
`;
export const getMeetingRoomBookings = /* GraphQL */ `
  query GetMeetingRoomBookings(
    $roomIds: [String!]
    $meetingRoomIds: [String]
    $date: String
    $dateTo: String
    $bookerId: String
  ) {
    getMeetingRoomBookings(
      roomIds: $roomIds
      meetingRoomIds: $meetingRoomIds
      date: $date
      dateTo: $dateTo
      bookerId: $bookerId
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        visitors
        meetingRoomSeatingConf
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeatBookings = /* GraphQL */ `
  query GetSeatBookings($input: GetSeatBookingsInput) {
    getSeatBookings(input: $input) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        orgUnitAdmin
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHintsByBuilding = /* GraphQL */ `
  query GetHintsByBuilding($buildingId: ID!) {
    getHintsByBuilding(buildingId: $buildingId) {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
  }
`;
export const getInventories = /* GraphQL */ `
  query GetInventories {
    getInventories {
      items {
        inventoryId
        orgUnitAdmin
        orgUnitId
        type
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeatConfigs = /* GraphQL */ `
  query GetSeatConfigs($roomId: ID!) {
    getSeatConfigs(roomId: $roomId) {
      items {
        seatName
        orgUnitAdmin
        roomId
        seatType
        inventory
        isSeatHeightAdjustable
        owner
        isOwnerRegistered
        neighborhoodId
        isBookable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInventoryByLowerCasedName = /* GraphQL */ `
  query GetInventoryByLowerCasedName($input: InventoryByLowerCasedNameInput) {
    getInventoryByLowerCasedName(input: $input) {
      items {
        inventoryId
        orgUnitAdmin
        orgUnitId
        type
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNeighborhoodsByRoomId = /* GraphQL */ `
  query GetNeighborhoodsByRoomId($roomId: ID!, $checkPermissions: Boolean) {
    getNeighborhoodsByRoomId(
      roomId: $roomId
      checkPermissions: $checkPermissions
    ) {
      items {
        neighborhoodId
        roomId
        restricted
        roleIds
        name
        adminRoleIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAllNeighborhoods = /* GraphQL */ `
  query GetAllNeighborhoods {
    getAllNeighborhoods {
      items {
        neighborhoodId
        roomId
        restricted
        roleIds
        name
        adminRoleIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeatUsageData = /* GraphQL */ `
  query GetSeatUsageData(
    $buildingId: ID!
    $dateStart: AWSDate!
    $dateEnd: AWSDate!
  ) {
    getSeatUsageData(
      buildingId: $buildingId
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      items {
        buildingId
        date
        bookable
        booked
        reserved
      }
      dataSince {
        dataSince
      }
    }
  }
`;
export const getBuilding = /* GraphQL */ `
  query GetBuilding($buildingId: ID!) {
    getBuilding(buildingId: $buildingId) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      seatConfEmail
      createdAt
      updatedAt
    }
  }
`;
export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $buildingId: ID
    $filter: ModelBuildingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBuildings(
      buildingId: $buildingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        buildingId
        buildingName
        roleIds
        buildingNameLowerCased
        seatConfEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($roomId: ID!) {
    getRoom(roomId: $roomId) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      urlKey
      createdAt
      updatedAt
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $roomId: ID
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRooms(
      roomId: $roomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        urlKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoom = /* GraphQL */ `
  query GetMeetingRoom($meetingRoomId: ID!, $roomId: ID!) {
    getMeetingRoom(meetingRoomId: $meetingRoomId, roomId: $roomId) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      seatingConfImageId
      seatingConfImageUrl
      seatingConfNames
      seatingConfDays
      createdAt
      updatedAt
    }
  }
`;
export const listMeetingRooms = /* GraphQL */ `
  query ListMeetingRooms(
    $meetingRoomId: ID
    $roomId: ModelIDKeyConditionInput
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetingRooms(
      meetingRoomId: $meetingRoomId
      roomId: $roomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        meetingRoomId
        roomId
        name
        nameLowerCased
        hintId
        type
        capacity
        roleIds
        neighborhoodId
        equipmentIds
        isBookable
        seatingConfImageId
        seatingConfImageUrl
        seatingConfNames
        seatingConfDays
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNeighborhood = /* GraphQL */ `
  query GetNeighborhood($neighborhoodId: ID!, $roomId: ID!) {
    getNeighborhood(neighborhoodId: $neighborhoodId, roomId: $roomId) {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
  }
`;
export const listNeighborhoods = /* GraphQL */ `
  query ListNeighborhoods(
    $neighborhoodId: ID
    $roomId: ModelIDKeyConditionInput
    $filter: ModelNeighborhoodFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNeighborhoods(
      neighborhoodId: $neighborhoodId
      roomId: $roomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        neighborhoodId
        roomId
        restricted
        roleIds
        name
        adminRoleIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($bookingId: ID!, $date: AWSDate!) {
    getBooking(bookingId: $bookingId, date: $date) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        urlKey
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $bookingId: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookings(
      bookingId: $bookingId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        orgUnitAdmin
        bookingFor
        timeBegin
        timeEnd
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoomBooking = /* GraphQL */ `
  query GetMeetingRoomBooking($bookingId: ID!, $date: AWSDate!) {
    getMeetingRoomBooking(bookingId: $bookingId, date: $date) {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      visitors
      meetingRoomSeatingConf
      createdAt
      updatedAt
    }
  }
`;
export const listMeetingRoomBookings = /* GraphQL */ `
  query ListMeetingRoomBookings(
    $bookingId: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelMeetingRoomBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetingRoomBookings(
      bookingId: $bookingId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        date
        meetingRoomId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        timeBegin
        timeEnd
        meetingName
        roomCapacity
        participantNumber
        meetingType
        visitors
        meetingRoomSeatingConf
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($inventoryId: ID!) {
    getInventory(inventoryId: $inventoryId) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories(
    $inventoryId: ID
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInventories(
      inventoryId: $inventoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        inventoryId
        orgUnitAdmin
        orgUnitId
        type
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeetingRoomEquipment = /* GraphQL */ `
  query GetMeetingRoomEquipment($equipmentId: ID!) {
    getMeetingRoomEquipment(equipmentId: $equipmentId) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const listMeetingRoomEquipments = /* GraphQL */ `
  query ListMeetingRoomEquipments(
    $equipmentId: ID
    $filter: ModelMeetingRoomEquipmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetingRoomEquipments(
      equipmentId: $equipmentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        equipmentId
        buildingId
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeatConfig = /* GraphQL */ `
  query GetSeatConfig($roomId: ID!, $seatName: String!) {
    getSeatConfig(roomId: $roomId, seatName: $seatName) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const listSeatConfigs = /* GraphQL */ `
  query ListSeatConfigs(
    $roomId: ID
    $seatName: ModelStringKeyConditionInput
    $filter: ModelSeatConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSeatConfigs(
      roomId: $roomId
      seatName: $seatName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        seatName
        orgUnitAdmin
        roomId
        seatType
        inventory
        isSeatHeightAdjustable
        owner
        isOwnerRegistered
        neighborhoodId
        isBookable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookingConfig = /* GraphQL */ `
  query GetBookingConfig($orgUnitId: ID!) {
    getBookingConfig(orgUnitId: $orgUnitId) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const listBookingConfigs = /* GraphQL */ `
  query ListBookingConfigs(
    $orgUnitId: ID
    $filter: ModelBookingConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookingConfigs(
      orgUnitId: $orgUnitId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orgUnitId
        orgUnitAdmin
        maxDaysInAdvance
        maxBookableDays
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMailConfig = /* GraphQL */ `
  query GetMailConfig($userId: ID!) {
    getMailConfig(userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const listMailConfigs = /* GraphQL */ `
  query ListMailConfigs(
    $userId: ID
    $filter: ModelMailConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMailConfigs(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDefaultRoomConfig = /* GraphQL */ `
  query GetDefaultRoomConfig($userId: ID!) {
    getDefaultRoomConfig(userId: $userId) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const listDefaultRoomConfigs = /* GraphQL */ `
  query ListDefaultRoomConfigs(
    $userId: ID
    $filter: ModelDefaultRoomConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDefaultRoomConfigs(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($userId: ID!) {
    getUserSettings(userId: $userId) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings(
    $userId: ID
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserSettings(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        consent
        createdAt
        updatedAt
        userSettingsMailConfigId
        userSettingsDefaultRoomConfigId
      }
      nextToken
    }
  }
`;
export const getInfoText = /* GraphQL */ `
  query GetInfoText($id: ID!) {
    getInfoText(id: $id) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
export const listInfoTexts = /* GraphQL */ `
  query ListInfoTexts(
    $id: ID
    $filter: ModelInfoTextFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInfoTexts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        infoText
        important
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomByBuildingId = /* GraphQL */ `
  query RoomByBuildingId(
    $buildingId: ID!
    $nameLowerCased: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomByBuildingId(
      buildingId: $buildingId
      nameLowerCased: $nameLowerCased
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        urlKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomByNameAndOrgUnitID = /* GraphQL */ `
  query RoomByNameAndOrgUnitID(
    $orgUnitId: ID!
    $nameLowerCased: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomByNameAndOrgUnitID(
      orgUnitId: $orgUnitId
      nameLowerCased: $nameLowerCased
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        urlKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const equipmentByBuildingIdAndNameLowerCased = /* GraphQL */ `
  query EquipmentByBuildingIdAndNameLowerCased(
    $nameLowerCased: String!
    $buildingId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    equipmentByBuildingIdAndNameLowerCased(
      nameLowerCased: $nameLowerCased
      buildingId: $buildingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        equipmentId
        buildingId
        name
        nameLowerCased
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const seatConfigBySeatName = /* GraphQL */ `
  query SeatConfigBySeatName(
    $seatName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSeatConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatConfigBySeatName(
      seatName: $seatName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        seatName
        orgUnitAdmin
        roomId
        seatType
        inventory
        isSeatHeightAdjustable
        owner
        isOwnerRegistered
        neighborhoodId
        isBookable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHint = /* GraphQL */ `
  query GetHint($hintId: ID!, $roomId: ID!) {
    getHint(hintId: $hintId, roomId: $roomId) {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
  }
`;
export const listHints = /* GraphQL */ `
  query ListHints(
    $hintId: ID
    $roomId: ModelIDKeyConditionInput
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHints(
      hintId: $hintId
      roomId: $roomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        hintId
        roomId
        buildingId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const hintsByRoom = /* GraphQL */ `
  query HintsByRoom(
    $roomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hintsByRoom(
      roomId: $roomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hintId
        roomId
        buildingId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const hintByBuildingId = /* GraphQL */ `
  query HintByBuildingId(
    $buildingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hintByBuildingId(
      buildingId: $buildingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        hintId
        roomId
        buildingId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
