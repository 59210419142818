import {NeighborhoodOfRoomPlan, useMeetRoomBkngSelectorData} from "../../hooks/useMeetRoomBkngSelectorData";
import {Building, DefaultRoomConfig, MeetingRoomEquipment, Room} from "../../API";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import GenericSelectorComponent from "../SelectorComponents/GenericSelectorComponent";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import uiElementMeasures from "../../styles/inputElementMeasures";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {
    getBuildingOptions,
    getMeetRoomsOfNeighborhoods,
    getNeighborhoodOptions,
    getNeighborhoodsOfRoomPlans,
    getRoomPlanOptions,
    getRoomPlansOfBuilding,
    SelectorOptions
} from "../../Utils/MeetingRoomBookingSelectorsUtil";
import {EN_DASH} from "../../Utils/commons";
import {MeetingRoomWithLevelP} from "../../types/PermissionHandling";
import {Button, FormControl, InputLabel, OutlinedInput} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import MultiSelectorDuplicities from "../MultiSelectorDuplicities";
import {Selection as ManagerSelection} from "./MeetingRoomBookingManagerComponent";

type Props = {
    setSelectedRoomPlans: (roomPlans: Room[]) => void
    setSelectedMeetingRooms: (meetRooms: MeetingRoomWithLevelP[]) => void
    setIsNeighborhoodFiltered: (isNeighborhoodFiltered: boolean) => void
    setIsTypeFiltered: (isTypeFiltered: boolean) => void
    setTypeFilter: (typeFilter: string) => void
    typeFilter: string
    defaultRoomConfig: DefaultRoomConfig | null | undefined
    buildingEquipment: MeetingRoomEquipment[],
    filteredEquipment: MeetingRoomEquipment[]
    setFilteredEquipment: (equip: MeetingRoomEquipment[]) => void
    selection: ManagerSelection,
    setSelection: (selection: (prevState: ManagerSelection) => any) => void,
    meetingRoomMinCapacity: number,
    onSetMeetingRoomMinCapacity: (minCapacity: number) => void
}


interface Options {
    buildingOptions: SelectorOptions<Building> | undefined,
    roomPlanOptions: SelectorOptions<Room> | undefined,
    neighborhoodOptions: SelectorOptions<NeighborhoodOfRoomPlan> | undefined,
    typeFilterOptions: SelectorOptions<string> | undefined,
    neighborhoodFilterOptions: SelectorOptions<string> | undefined
}

const NO_NEIGHBORHOOD_NAME = "";
const NO_NEIGHBORHOOD_LABEL_TEXT = EN_DASH;

function MeetingRoomBookingSelectorsComponent(props: Readonly<Props>) {
    const {
        setSelectedRoomPlans,
        setSelectedMeetingRooms,
        setIsNeighborhoodFiltered,
        setIsTypeFiltered,
        setTypeFilter,
        typeFilter,
        defaultRoomConfig,
        buildingEquipment,
        filteredEquipment,
        setFilteredEquipment,
        selection,
        setSelection,
        meetingRoomMinCapacity,
        onSetMeetingRoomMinCapacity
    } = props;
    const {t} = useTranslation();
    const {result: selectorData, loading} = useMeetRoomBkngSelectorData();
    const [allBuildings, allRoomPlans, allNeighborhoods, allMeetingRooms] = selectorData;
    const [selectedBuildingIndex, setSelectedBuildingIndex] = useState<number | undefined>(undefined);
    const [selectedRoomPlanIndex, setSelectedRoomPlanIndex] = useState<number | undefined>(undefined);
    const [selectedNeighborhoodIndex, setSelectedNeighborhoodIndex] = useState<number | undefined>(undefined);
    const [selectedTypeFilterIndex, setSelectedTypeFilterIndex] = useState<number | undefined>(undefined)
    const [selectedNeighborhoodFilterIndex, setSelectedNeighborhoodFilterIndex] = useState<number | undefined>(undefined);
    const [isFilterHidden, setIsFilterHidden] = useState(false);
    const {isMobile} = useDeviceMediaType();

    const neighborhoodFilterOptions: SelectorOptions<string> = {
        options: [
            {
                label: t('Meeting_Room_Bookings_Neighborhood_Filter_My_Neighborhood'),
                element: [t('Meeting_Room_Bookings_Neighborhood_Filter_My_Neighborhood')]
            },
            {
                label: t('Meeting_Room_Bookings_Neighborhood_Filter_All_Neighborhoods'),
                element: [t('Meeting_Room_Bookings_Neighborhood_Filter_All_Neighborhoods')]
            }
        ],
        defaultSelection: {
            label: t('Meeting_Room_Bookings_Neighborhood_Filter_My_Neighborhood'),
            element: [t('Meeting_Room_Bookings_Neighborhood_Filter_My_Neighborhood')]
        },
        defaultSelectionIndex: 0
    };

    const typeFilterOptions: SelectorOptions<string> = {
        options: [
            {
                label: t('Meeting_Room_Bookings_Type_Filter_All'),
                element: [t('Meeting_Room_Bookings_Type_Filter_All')]
            },
            {
                label: t('meetingroom_type_classroom'),
                element: [t('meetingroom_type_classroom')]
            },
            {
                label: t('meetingroom_type_internal'),
                element: [t('meetingroom_type_internal')]
            },
            {
                label: t('meetingroom_type_neighborhood'),
                element: [t('meetingroom_type_neighborhood')]
            },
            {
                label: t('meetingroom_type_visitors_center'),
                element: [t('meetingroom_type_visitors_center')]
            },
        ],
        defaultSelection: {
            label: t('Meeting_Room_Bookings_Type_Filter_All'),
            element: [t('Meeting_Room_Bookings_Type_Filter_All')]
        },
        defaultSelectionIndex: 0
    };

    const [options,
        setOptions] =
        useState<Options>({
            buildingOptions: undefined,
            roomPlanOptions: undefined,
            neighborhoodOptions: undefined,
            typeFilterOptions: undefined,
            neighborhoodFilterOptions: undefined
        })

    const {idOfDefaultBuilding} = useMainApplicationContext();

    function buildingsSetOptionsAndSelectDefault() {
        const defaultUserRoomPlanId = defaultRoomConfig?.roomId;
        let buildingId;
        let buildingIdOfDefaultRoomConfig;

        if (defaultUserRoomPlanId) {
            const defaultUserRoomPlan = allRoomPlans.find(roomPlan => {
                return roomPlan.roomPlanId === defaultUserRoomPlanId;
            });
            if (defaultUserRoomPlan) {
                buildingIdOfDefaultRoomConfig = defaultUserRoomPlan?.buildingId;
            }
        }

        buildingId = buildingIdOfDefaultRoomConfig ? buildingIdOfDefaultRoomConfig : idOfDefaultBuilding;
        const optsBuilding = getBuildingOptions(allBuildings, buildingId);
        setOptions(prevState => {
            return {...prevState, buildingOptions: optsBuilding}
        })

        const buildingToSelect = optsBuilding?.defaultSelection.element[0];

        if (buildingToSelect === undefined) {
            return undefined;
        }
        onSelectBuilding([buildingToSelect]);
        return buildingToSelect;
    }

    function roomPlansSetOptionsAndSelectDefault(building: Building | undefined) {
        if (building === undefined) {
            return;
        }

        const optsRoomPlans = getRoomPlanOptions(
            getRoomPlansOfBuilding(allRoomPlans, selection.building),
            t("all_room-plans"));
        setOptions(prevState => {
            return {...prevState, roomPlanOptions: optsRoomPlans}
        })

        const roomPlansToSelect = optsRoomPlans?.defaultSelection.element;

        if (roomPlansToSelect === undefined) {
            return;
        }
        onSelectRoomPlan(roomPlansToSelect);
        return roomPlansToSelect;
    }

    function neighborhoodsSetOptionsAndSelectDefault(roomPlans: Room[] | undefined) {
        if (roomPlans === undefined) {
            return undefined;
        }

        const optsNeighborhoods = getNeighborhoodOptions(getNeighborhoodsOfRoomPlans(allNeighborhoods, roomPlans),
            t("all_neighborhoods"), NO_NEIGHBORHOOD_LABEL_TEXT);
        setOptions(prevState => {
            return {...prevState, neighborhoodOptions: optsNeighborhoods}
        })
        const neighborhoodsToSelect = optsNeighborhoods?.defaultSelection.element;
        if (neighborhoodsToSelect === undefined) {
            return undefined;
        }
        onSelectNeighborhood(neighborhoodsToSelect);
    }

    function typeFilterSetOptionsAndSelectDefault() {
        setOptions(prevState => {
            return {...prevState, typeFilterOptions: typeFilterOptions}
        })
        const typeFilterToSelect = typeFilterOptions?.defaultSelection.element[0];
        onSelectTypeFilter([typeFilterToSelect], typeFilterOptions.defaultSelectionIndex);
        return typeFilterToSelect;
    }

    function neighborhoodFilterSetOptionsAndSelectDefault() {
        setOptions(prevState => {
            return {...prevState, neighborhoodFilterOptions: neighborhoodFilterOptions}
        })
        const filterToSelect = neighborhoodFilterOptions?.defaultSelection.element[0];
        onSelectNeighborhoodFilter([filterToSelect], neighborhoodFilterOptions.defaultSelectionIndex);
        return filterToSelect;
    }


    useEffect(() => {
        setSelectedNeighborhoodIndex(options.neighborhoodOptions?.defaultSelectionIndex);
    }, [options])

    useEffect(() => {
        setFilteredEquipment([]);
        const roomPlans = roomPlansSetOptionsAndSelectDefault(selection.building);
        neighborhoodsSetOptionsAndSelectDefault(roomPlans);
    }, [selection.building]);


    useEffect(() => {
        neighborhoodsSetOptionsAndSelectDefault(selection.roomPlans);
    }, [selection.roomPlans]);

    useEffect(function selectDefaultsAndSetOptions() {
        if (selectorData.some(list => list.length === 0)) {
            return;
        }
        const building = buildingsSetOptionsAndSelectDefault();
        const roomPlans = roomPlansSetOptionsAndSelectDefault(building);
        neighborhoodsSetOptionsAndSelectDefault(roomPlans);
        neighborhoodFilterSetOptionsAndSelectDefault();
        typeFilterSetOptionsAndSelectDefault();
    }, [selectorData, defaultRoomConfig?.roomId]);

    function isRealNeighborhoodPresent(neighborhoods: NeighborhoodOfRoomPlan[]) {
        return neighborhoods.some(nbhd => nbhd.neighborhoodName !== NO_NEIGHBORHOOD_NAME);
    }

    const showNeighborhoodSelector = (selection.roomPlans !== undefined) &&
        isRealNeighborhoodPresent(getNeighborhoodsOfRoomPlans(allNeighborhoods, selection.roomPlans));

    function onSelectBuilding(buildings: Building[], index?: number) {
        setSelection(prevState => {
            return {...prevState, building: buildings[0]}
        });
        setSelectedBuildingIndex(index);
        setSelectedRoomPlanIndex(options?.roomPlanOptions?.defaultSelectionIndex)
        setSelectedNeighborhoodIndex(options?.neighborhoodOptions?.defaultSelectionIndex)
    }

    function onSelectRoomPlan(roomPlans: Room[], index?: number) {
        setSelection(prevState => {
            return {...prevState, roomPlans: roomPlans}
        });
        setSelectedRoomPlans(roomPlans);
        setSelectedRoomPlanIndex(index);
        setSelectedNeighborhoodIndex(options?.neighborhoodOptions?.defaultSelectionIndex)
    }

    function onSelectNeighborhood(neighborhoods: NeighborhoodOfRoomPlan[], index?: number) {
        const meetRooms = getMeetRoomsOfNeighborhoods(allMeetingRooms, neighborhoods);
        setSelection(prevState => {
            return {...prevState, meetingRooms: meetRooms}
        });
        setSelectedMeetingRooms(meetRooms);
        setSelectedNeighborhoodIndex(index);
    }

    function onSelectTypeFilter(types: string[], index?: number) {
        setSelection(prevState => {
            return {...prevState, types: types}
        });
        const defaultLabel = typeFilterOptions.defaultSelection.label;

        if (types[0] === defaultLabel) {
            setIsTypeFiltered(false);
            setTypeFilter(defaultLabel)
            setSelectedTypeFilterIndex(options?.typeFilterOptions?.defaultSelectionIndex)
        } else {
            setIsTypeFiltered(true);
            setTypeFilter(types[0])
            setSelectedTypeFilterIndex(index)
        }
    }

    function onSelectNeighborhoodFilter(filter: string[], index?: number) {
        if (filter[0] === neighborhoodFilterOptions.defaultSelection.label) {
            setIsNeighborhoodFiltered(true);
        } else {
            setIsNeighborhoodFiltered(false);
        }
        setSelectedNeighborhoodFilterIndex(index);
    }

    function filterMeetingRoomsByMinCapacity(minCapacityInput: string) {
        const minCapacityInputParsed = parseInt(minCapacityInput);
        const minCapacity = isNaN(minCapacityInputParsed) ? 0 : minCapacityInputParsed;
        onSetMeetingRoomMinCapacity(minCapacity);
    }

    const isTypeFilterAllOrNeighborhood = () => {
        return typeFilter === t('Meeting_Room_Bookings_Type_Filter_All') || typeFilter === t('meetingroom_type_neighborhood')
    }


    const renderFilterForMobile = () => {
        if (loading ||
            options.buildingOptions === undefined ||
            options.roomPlanOptions === undefined ||
            options.neighborhoodOptions === undefined ||
            options.typeFilterOptions === undefined ||
            options.neighborhoodFilterOptions === undefined
        ) {
            return <Skeleton width={"100%"} height={"100px"}/>
        }
        return (
            <>
                <Button onClick={() => setIsFilterHidden(val => !val)}
                        startIcon={!isFilterHidden ? <ExpandLess/> : <ExpandMore/>}
                        endIcon={!isFilterHidden ? <ExpandLess/> : <ExpandMore/>}
                        style={{
                            width: "100%",
                            height: "40px"
                        }}>{isFilterHidden ? t("meeting_room_bookings_show_filter") : t("meeting_room_bookings_hide_filter")}
                </Button>

                {!isFilterHidden && <Box sx={{display: "flex", flexDirection: "column", flex: 1}}>
                    <Box sx={{
                        display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                        marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                    }}>
                        <GenericSelectorComponent disabled={options.buildingOptions.options.length === 1}
                                                  labelText={t("general_building-singular")}
                                                  chooseFrom={options.buildingOptions.options}
                                                  selectedIndex={selectedBuildingIndex ?? options.buildingOptions.defaultSelectionIndex}
                                                  setSelected={onSelectBuilding}
                                                  style={{flexGrow: 1, flexBasis: 0}}
                                                  isOutlined={true}/>
                        <GenericSelectorComponent disabled={options.roomPlanOptions.options.length === 1}
                                                  labelText={t("general_room-plan-singular")}
                                                  chooseFrom={options.roomPlanOptions.options}
                                                  selectedIndex={selectedRoomPlanIndex ?? options.roomPlanOptions.defaultSelectionIndex}
                                                  setSelected={onSelectRoomPlan}
                                                  style={{flexGrow: 1, flexBasis: 0}}
                                                  isOutlined={true}/>
                    </Box>
                    <Box sx={{
                        display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                        marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                    }}>
                        {showNeighborhoodSelector ?
                            <GenericSelectorComponent disabled={options.neighborhoodOptions.options.length === 1}
                                                      labelText={t("general_neighborhood-singular")}
                                                      chooseFrom={options.neighborhoodOptions.options}
                                                      selectedIndex={selectedNeighborhoodIndex ?? options.neighborhoodOptions.defaultSelectionIndex}
                                                      setSelected={onSelectNeighborhood}
                                                      style={{flexGrow: 1, flexBasis: 0}}
                                                      isOutlined={true}/> : null}
                        <GenericSelectorComponent disabled={options.typeFilterOptions!.options.length === 1}
                                                  labelText={t("Meeting_Room_Bookings_Type_Filter_Label")}
                                                  chooseFrom={options.typeFilterOptions!.options}
                                                  selectedIndex={selectedTypeFilterIndex ?? options.typeFilterOptions!.defaultSelectionIndex}
                                                  setSelected={onSelectTypeFilter}
                                                  style={{flexGrow: 1, flexBasis: 0}}
                                                  isOutlined={true}/>
                    </Box>
                    <Box sx={{
                        display: "flex", flex: 1, gap: uiElementMeasures.marginBetweenElementsInColumn,
                        marginBottom: uiElementMeasures.marginBetweenElementsInColumn
                    }}>
                        <GenericSelectorComponent
                            disabled={options.neighborhoodFilterOptions!.defaultSelectionIndex === 1
                                || !isTypeFilterAllOrNeighborhood()}
                            labelText={t("Meeting_Room_Bookings_Neighborhood_Filter_Label")}
                            chooseFrom={options.neighborhoodFilterOptions!.options}
                            selectedIndex={selectedNeighborhoodFilterIndex ?? options.neighborhoodFilterOptions!.defaultSelectionIndex}
                            setSelected={onSelectNeighborhoodFilter}
                            style={{flexGrow: 1, flexBasis: 0}}
                            isOutlined={true}/>
                    </Box>
                </Box>}
            </>
        )
    }

    const renderFilterForDesktop = () => {
        if (loading ||
            options.buildingOptions === undefined ||
            options.roomPlanOptions === undefined ||
            options.neighborhoodOptions === undefined ||
            options.typeFilterOptions === undefined ||
            options.neighborhoodFilterOptions === undefined
        ) {
            return <Skeleton width={"100%"} height={"100px"}/>
        }
        return (
            <><Box sx={{
                display: "flex", gap: uiElementMeasures.marginBetweenElementsInColumn,
                marginBottom: uiElementMeasures.marginBetweenElementsInColumn
            }}>
                <GenericSelectorComponent disabled={options.buildingOptions.options.length === 1}
                                          labelText={t("general_building-singular")}
                                          chooseFrom={options.buildingOptions.options}
                                          selectedIndex={selectedBuildingIndex ?? options.buildingOptions.defaultSelectionIndex}
                                          setSelected={onSelectBuilding}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}/>
                <GenericSelectorComponent disabled={options.roomPlanOptions.options.length === 1}
                                          labelText={t("general_room-plan-singular")}
                                          chooseFrom={options.roomPlanOptions.options}
                                          selectedIndex={selectedRoomPlanIndex ?? options.roomPlanOptions.defaultSelectionIndex}
                                          setSelected={onSelectRoomPlan}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}/>
                {showNeighborhoodSelector ?
                    <GenericSelectorComponent disabled={options.neighborhoodOptions.options.length === 1}
                                              labelText={t("general_neighborhood-singular")}
                                              chooseFrom={options.neighborhoodOptions.options}
                                              selectedIndex={selectedNeighborhoodIndex ?? options.neighborhoodOptions.defaultSelectionIndex}
                                              setSelected={onSelectNeighborhood}
                                              style={{flexGrow: 1, flexBasis: 0}}
                                              isOutlined={true}/> : null}
                <GenericSelectorComponent disabled={options.typeFilterOptions!.options.length === 1}
                                          labelText={t("Meeting_Room_Bookings_Type_Filter_Label")}
                                          chooseFrom={options.typeFilterOptions!.options}
                                          selectedIndex={selectedTypeFilterIndex ?? options.typeFilterOptions!.defaultSelectionIndex}
                                          setSelected={onSelectTypeFilter}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}/>
                <FormControl variant="outlined" style={{flexGrow: 1, flexBasis: 0}}>
                    <InputLabel id="min-cap-picker-label">{t("Meeting_Room_Bookings_Min_Cap_Filter_Label")}</InputLabel>
                    <OutlinedInput type="number" label={t("Meeting_Room_Bookings_Min_Cap_Filter_Label")}
                                   fullWidth value={meetingRoomMinCapacity.toString()}
                                   inputProps={{min: "0", step: "1"}}
                                   onChange={e => filterMeetingRoomsByMinCapacity(e.target.value)}/>
                </FormControl>


                <GenericSelectorComponent disabled={options.neighborhoodFilterOptions!.defaultSelectionIndex === 1
                    || !isTypeFilterAllOrNeighborhood()}
                                          labelText={t("Meeting_Room_Bookings_Neighborhood_Filter_Label")}
                                          chooseFrom={options.neighborhoodFilterOptions!.options}
                                          selectedIndex={selectedNeighborhoodFilterIndex ?? options.neighborhoodFilterOptions!.defaultSelectionIndex}
                                          setSelected={onSelectNeighborhoodFilter}
                                          style={{flexGrow: 1, flexBasis: 0}}
                                          isOutlined={true}/>
            </Box>
                {buildingEquipment?.length > 0 && <Box sx={{display: "flex", width: "inherit"}}>
                    <FormControl
                        style={{
                            width: "100%"
                        }}
                        data-testid={"matrix-equipment-filter"}>
                        <MultiSelectorDuplicities options={buildingEquipment}
                                                  title={t("general_equipment-singular")}
                                                  selected={filteredEquipment}
                                                  onChange={(selection) => setFilteredEquipment(selection)}
                                                  disabled={!buildingEquipment.length}
                                                  getLabel={(equipment: MeetingRoomEquipment) => equipment.name}/>
                    </FormControl>
                </Box>}
            </>
        );
    }

    return isMobile ? renderFilterForMobile() : renderFilterForDesktop();

}

export default MeetingRoomBookingSelectorsComponent;
