import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import awsExports from "./aws-exports";
import Amplify from "@aws-amplify/core";
import {Auth} from "@aws-amplify/auth";
import {BrowserRouter} from "react-router-dom";

Amplify.configure(awsExports);
Auth.configure({
    cookieStorage: {
        domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
        secure: false
    }
})

ReactDOM.render(
    <React.Fragment>
            <App/>
    </React.Fragment>,
    document.getElementById("root")
);
reportWebVitals();
