import {MeetingRoomSVGUpdateData, updateSVGMeetingRooms} from "../../../Utils/svgPlans/meetingRooms";
import {Neighborhood} from "../../../API";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {updateSVGNeighborhoodLabels} from "../../../Utils/svgPlans/neighborhoods";

interface UseFinalRoomPlanXMLDocumentResult {
    finalXMLDocument: XMLDocument | null
}

export function useFinalRoomPlanXMLDocument(xmlDocument: XMLDocument | null, meetingRoomsToUpdate: MeetingRoomSVGUpdateData[], neighborhoods: Neighborhood[]): UseFinalRoomPlanXMLDocumentResult {
    const {t} = useTranslation();
    const finalXMLDocument = useMemo(() => {
        if (xmlDocument === null) {
            return null;
        }
        // don't change base xmlDocument
        const newXMLDocument = xmlDocument.implementation.createDocument(null, null, null);
        const copiedRoot = newXMLDocument.importNode(xmlDocument.documentElement, true);
        newXMLDocument.appendChild(copiedRoot);

        updateSVGMeetingRooms(newXMLDocument, meetingRoomsToUpdate, t("meeting_room_capacity"));
        updateSVGNeighborhoodLabels(newXMLDocument, neighborhoods);

        return newXMLDocument;
    }, [xmlDocument, meetingRoomsToUpdate, t, neighborhoods]);
    return {finalXMLDocument}
}