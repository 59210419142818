import {Hint} from "../../API";
import {gql, useQuery} from "@apollo/client";
import {getHintsByBuilding} from "../../graphql/queries";
import {useEffect} from "react";

const NO_HINTS: Hint[] = [];

interface UseHintsByBuildingResult {
    hints: Hint[]
}

export function useHintsOfBuildingByBuildingId(buildingId: string): UseHintsByBuildingResult {
    const {data, refetch} = useQuery(gql(getHintsByBuilding), {
        variables: {buildingId: buildingId},
        skip: !buildingId,
    });
    useEffect(() => {
        if (buildingId) {
            refetch()
                .catch(
                    (error) => {
                        console.error("Error fetching hints:", error)
                    });
        }
    }, [buildingId, refetch]);

    let hints = data?.getHintsByBuilding ?? [];
    if (hints.length === 0 || !buildingId) {
        hints = NO_HINTS;
    }
    return {hints};
}