import {SeatBookings} from "../../../types/SeatBookinType";
import {SeatConfig} from "../../../API";
import React, {useMemo} from "react";
import {useMainApplicationContext} from "../../../hooks/useMainApplicationContext";
import SeatComponent from "../SeatComponent";
import {isSeatBookable} from "../../../Utils/seats";

interface SeatOverlayProps {
    seatBookings: SeatBookings[]
    onSeatClick: (data: SeatBookings) => void
    userIsBuildingAdmin: boolean
    seatIdToConfig: Map<string, SeatConfig>
    isTimeBookingActive: boolean
    rerenderSeatConfigsTrigger: boolean;
    neighborhoodAdminIds: string[]
    userIsOrgUnitAdminOfRoom: boolean
}

export const SeatOverlay: React.FC<SeatOverlayProps> = (props) => {
    const {
        seatBookings, onSeatClick,
        userIsBuildingAdmin,
        seatIdToConfig, isTimeBookingActive,
        rerenderSeatConfigsTrigger,
        neighborhoodAdminIds,
        userIsOrgUnitAdminOfRoom
    } = props;

    const {currentUser} = useMainApplicationContext();

    function hasAccessToNeighborhoodById(neighborhoodId: string) {
        return neighborhoodAdminIds.includes(neighborhoodId) || neighborhoodId === "" || userIsOrgUnitAdminOfRoom || currentUser?.isAdmin;
    }

    const memoizedComponents = useMemo(() => {
        return seatBookings.map((seatBooking: SeatBookings) => {
            //TODO refactor neighborhood access handling
            const hasAccess = userIsBuildingAdmin || hasAccessToNeighborhoodById(seatBooking.seat.neighborhoodId);
            const seatId = seatBooking.seat.seatID;
            const isBookable = isSeatBookable(seatId, seatIdToConfig);
            if (isBookable !== null) {
                return <SeatComponent
                    key={seatId}
                    seatId={seatId}
                    onClick={onSeatClick}
                    seatBookings={seatBooking}
                    isTimeBookingActive={isTimeBookingActive}
                    hasAccessToSeatByNeighborhood={hasAccess}
                    isBookable={isBookable}
                />
            }
            return <></>
        })
    }, [userIsBuildingAdmin, seatIdToConfig, seatBookings, onSeatClick, isTimeBookingActive, rerenderSeatConfigsTrigger]);
    return <>{memoizedComponents}</>;
}