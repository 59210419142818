import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {useTranslation} from "react-i18next";
import EditMeetingRoom from "./EditMeetingRoom";
import {MeetingRoom, MeetingRoomEquipment, Room, UpdateMeetingRoomInput} from "../API";
import {gql, useMutation, useQuery} from "@apollo/client";
import {deleteAllMeetingRoomBookings, updateSecureMeetingRoom} from "../graphql/mutations";
import Alert from "@material-ui/lab/Alert";
import {getMeetingRoomsByBuildingId, listMeetingRoomEquipments} from "../graphql/queries";
import {useMeetingRoomList} from "../hooks/useMeetingRoomList";
import {getMeetingRoomTypeLabel} from "../Utils/Enums";
import {usePermissionHelper} from "../hooks/usePermissionHelper";
import CloseButton from "./Buttons/CloseButton";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";

interface Props {
    selectedRoom: Room;
    showMeetingRoomManager: boolean;
    setShowMeetingRoomManager: (value: boolean) => void;
}

const MeetingRoomManagerComponent: React.FC<Props> = props => {
    const {
        showMeetingRoomManager,
        setShowMeetingRoomManager,
        selectedRoom
    } = props;
    const permissionHelper = usePermissionHelper();
    const [showEditMeetingRoom, setShowEditMeetingRoom] = useState(false);
    const [buildingMeetingRooms, setBuildingMeetingRooms] = useState<MeetingRoom[]>([]);
    const [selectedMeetingRoom, setSelectedMeetingRoom] = useState<MeetingRoom | null>(null);
    const [errorCode, setErrorCode] = useState("");
    const [isShowAlert, setIsShowAlert] = React.useState(false)
    const previouslySelectedBuildingId = useRef<string | null | undefined>(null);
    const [updateMeetingRoomMutation] = useMutation(gql(updateSecureMeetingRoom))
    const [deleteMeetingRoomBookingsMutation] = useMutation(gql(deleteAllMeetingRoomBookings));
    const [roomMeetingRooms] = useMeetingRoomList([selectedRoom.roomId]);
    const [currentMeetingRooms, setCurrentMeetingRooms] = useState<MeetingRoom[]>(roomMeetingRooms);
    const {t} = useTranslation();
    const {data, loading, error, refetch} = useQuery(gql(getMeetingRoomsByBuildingId), {
        variables: {buildingId: selectedRoom?.buildingId},
        skip: !selectedRoom?.buildingId,
    });
    const [equipmentArray, setEquipmentArray] = useState<MeetingRoomEquipment[]>([]);
    const {data: data1, loading: loading1, error: error1} = useQuery(gql(listMeetingRoomEquipments), {});

    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    useEffect(() => {
        // Check if the filtered meeting rooms are different before updating the state
        const newMeetingRooms = roomMeetingRooms.filter(mr => permissionHelper.hasManagementForMeetingRoom(mr));
        if (JSON.stringify(newMeetingRooms) !== JSON.stringify(currentMeetingRooms)) {
            setCurrentMeetingRooms(newMeetingRooms);
        }
    }, [roomMeetingRooms, currentMeetingRooms]);

    const handleEditMeetingRoom = useCallback(() => {
        setShowEditMeetingRoom(true);
    }, []);

    const handleCloseMeetingRoomManager = () => {
        setShowMeetingRoomManager(false);
    };

    const handleSaveMeetingRoom = (meetingRoom: MeetingRoom, leaveEditMeetingRoomOpen = false) => {
        const params: UpdateMeetingRoomInput = {
            meetingRoomId: meetingRoom.meetingRoomId,
            name: meetingRoom.name,
            nameLowerCased: meetingRoom.name.toLowerCase(),
            capacity: meetingRoom.capacity,
            type: meetingRoom.type,
            hintId: meetingRoom.hintId,
            roomId: meetingRoom.roomId,
            roleIds: meetingRoom.roleIds,
            neighborhoodId: meetingRoom.neighborhoodId,
            isBookable: meetingRoom.isBookable,
            equipmentIds: meetingRoom.equipmentIds,
            seatingConfNames: meetingRoom.seatingConfNames,
            seatingConfDays: meetingRoom.seatingConfDays,
            seatingConfImageId: meetingRoom.seatingConfImageId
        }
        updateMeetingRoomMutation({
            variables: {
                input: params
            }
        }).catch((error) => {
            handleApiError(error);
            showErrorMsg();
        }).then(() => {
            refetch().then(response => {
                setBuildingMeetingRooms(response.data.getMeetingRoomsByBuildingId);
            }).catch(error => {
                handleApiError(error);
                showErrorMsg();
            });
        }).then(() => {
            if (!meetingRoom.isBookable) {
                deleteMeetingRoomBookingsMutation({
                    variables: {
                        meetingRoomId: meetingRoom.meetingRoomId
                    }
                }).then().catch(error => {
                    console.error("error occurred when trying to delete meeting room bookings: " + JSON.stringify(error));
                })
            }
        });
        if(!leaveEditMeetingRoomOpen)
        setShowEditMeetingRoom(false);
    };

    const handleMeetingRoomChange = (index: number) => {
        if (currentMeetingRooms && !showEditMeetingRoom) {
            setSelectedMeetingRoom(currentMeetingRooms[index]!);
        }
    };

    const showErrorMsg = () => {
        setIsShowAlert(true);
        setTimeout(() => {
            setIsShowAlert(false)
        }, 4000);
    }

    const renderEquipmentCell = (meetingRoom: MeetingRoom): string => {
        const meetingRoomsMap = new Map<string, number>();

        if (meetingRoom.equipmentIds) {
            meetingRoom.equipmentIds.forEach(equipmentId => {
                // Find equipment using the equipmentArray defined in the scope
                const equipment = equipmentArray.find(item => item.equipmentId === equipmentId);
                if (equipment) {
                    // Increment count or initialize it to 1 if not present
                    const currentCount = meetingRoomsMap.get(equipment.name) || 0;
                    meetingRoomsMap.set(equipment.name, currentCount + 1);
                }
            });
        }

        return Array.from(meetingRoomsMap.keys()).sort().map(e => {
            const count = meetingRoomsMap.get(e)
            if (count && count > 1) {
                return count + "x " + e
            }
            return e
        }).join(",") ?? "";
    };

    useEffect(function fetchMeetingRoomsByBuildingId() {
        const shouldUpdateBuildingMeetingRooms = !loading && !error && data &&
            (selectedRoom?.buildingId !== previouslySelectedBuildingId.current);

        if (error) {
            handleApiError(error);
            showErrorMsg();
        } else if (shouldUpdateBuildingMeetingRooms) {
            setBuildingMeetingRooms(data.getMeetingRoomsByBuildingId);
            previouslySelectedBuildingId.current = selectedRoom?.buildingId;
        }

    }, [data, loading, error, selectedRoom?.buildingId]);

    useEffect(() => {
        if (!loading1) {
            setEquipmentArray(data1.listMeetingRoomEquipments.items.filter((item: {
                buildingId: string | null | undefined;
            }) => item.buildingId === previouslySelectedBuildingId.current))
        } else if (error1) {
            handleApiError(error1);
        }

    }, [data1, loading1, error1, previouslySelectedBuildingId.current]);


    const handleApiError = (error: { message: string }) => {
        console.error(error);
        try {
            if (error.message) {
                setErrorCode(error.message);
            } else {
                setErrorCode("UNKNOWN_ERROR");
            }
        } catch (e) {
            setErrorCode("UNKNOWN_ERROR");
        }
        setIsShowAlert(true);
    };

    const getErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            case "INVALID_NAME":
                return "meeting_room_invalid_name_error_text";
            case "NAME_EXISTS":
                return "meeting_room_name_exists_error_text";
            case "INVALID_CAPACITY":
                return "meeting_room_invalid_capacity_error_text";
            case "INTERNAL_SERVER_ERROR":
                return "internal_server_error_text";
            default:
                return "unknown_error_text";
        }
    };

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth
            open={showMeetingRoomManager}
            data-testid={"meetingRoomManager"}
        >
            {isShowAlert &&
                <Alert severity="error">
                    {t(getErrorMessage(errorCode))}
                </Alert>
            }
            <DialogTitle>{t("meeting_rooms_management_dialog-title")}</DialogTitle>

            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                data-testid={"meeting-room-id-column"}
                                style={{wordBreak: "break-word"}}
                            >
                                {t("meeting_room_table_id_column")}
                            </TableCell>
                            <TableCell
                                data-testid={"meeting-room-name-column-name"}
                                style={{wordBreak: "break-word"}}
                            >
                                {t("meeting_room_table_name_column")}
                            </TableCell>
                            <TableCell
                                data-testid={"meeting-room-capacity-column-capacity"}
                                style={{wordBreak: "break-word"}}
                            >
                                {t("meeting_room_table_capacity_column")}
                            </TableCell>
                            <TableCell
                                data-testid={"meeting-room-type-column-type"}
                                style={{wordBreak: "break-word"}}
                            >
                                {t("meeting_room_table_type_column")}
                            </TableCell>
                            <TableCell
                                data-testid={"meeting-room-equipment-column"}
                                style={{wordBreak: "break-word"}}
                            >
                                {t("meeting_room_table_equipment_column")}
                            </TableCell>
                            <TableCell
                                data-testid={"meeting-room-type-column-bookable"}
                                style={{wordBreak: "break-word"}}
                            >
                                {t("meeting_room_table_bookable_column")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentMeetingRooms.map((meetingRoom, index) => (
                            <TableRow
                                data-testid={`meetingRoom-${index}`}
                                onClick={() => handleMeetingRoomChange(index)}
                                key={meetingRoom.meetingRoomId}
                                style={{cursor: "pointer"}}
                                selected={currentMeetingRooms[index].meetingRoomId === selectedMeetingRoom?.meetingRoomId}
                            >
                                <TableCell>
                                    {meetingRoom.meetingRoomId}
                                </TableCell>
                                <TableCell>
                                    {meetingRoom.name}
                                </TableCell>
                                <TableCell>
                                    {meetingRoom.capacity}
                                </TableCell>
                                <TableCell>
                                    {t(getMeetingRoomTypeLabel(meetingRoom.type))}
                                </TableCell>
                                <TableCell style={{maxWidth: "300px"}}>
                                    {renderEquipmentCell(meetingRoom)}
                                </TableCell>
                                <TableCell>
                                    {meetingRoom.isBookable ?
                                        <CheckCircleIcon style={{color: "green"}}/> :
                                        <CancelIcon style={{color: "red"}}/>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    data-testid={"close-btn"}
                >
                </CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleEditMeetingRoom}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"show-meeting-room-config-btn"}
                    disabled={!selectedMeetingRoom}
                >
                    {t("inv_mngmnt_edit_button-text")}
                </Button>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleCloseMeetingRoomManager}
                    data-testid={"close-btn"}
                >
                </CloseButton>
            </DialogActions>

            {selectedMeetingRoom && <EditMeetingRoom
                selectedMeetingRoom={selectedMeetingRoom}
                meetingRoomEquipment={
                    equipmentArray.filter(equipment => selectedMeetingRoom?.equipmentIds?.includes(equipment.equipmentId))
                }
                buildingEquipment={equipmentArray}
                buildingId={selectedRoom?.buildingId ?? previouslySelectedBuildingId.current}
                open={showEditMeetingRoom}
                onClose={() => {
                    setShowEditMeetingRoom(false);
                }}
                onSave={handleSaveMeetingRoom}
                meetingRooms={selectedRoom.buildingId ? buildingMeetingRooms : currentMeetingRooms}
            />}
        </Dialog>
    );
};

export default MeetingRoomManagerComponent;
