import {useMainApplicationContext} from "../useMainApplicationContext";
import {useMemo} from "react";

export function useIsUserAdminOfBuilding(buildingId: string) {
    const {managedBuildings} = useMainApplicationContext();

    return useMemo(() => {
        const userIsBuildingAdmin = !(!managedBuildings || !buildingId) && managedBuildings.some(b => b.buildingId === buildingId);
        return {userIsBuildingAdmin};
    }, [buildingId, managedBuildings]);
}