import {BookingP, MeetingRoomBookingP} from "../../types/PermissionHandling";
import {useMemo} from "react";

export function useSplitSeatAndMeetingRoomBookings(bookings: (BookingP | MeetingRoomBookingP)[]) {
    return useMemo(() => {
        const isSeatBooking = (item: BookingP | MeetingRoomBookingP): item is BookingP => item.__typename === "Booking";
        const isMeetingRoomBooking = (item: BookingP | MeetingRoomBookingP): item is MeetingRoomBookingP => item.__typename === "MeetingRoomBooking";
        const seatBookings = bookings.filter(isSeatBooking);
        const meetingRoomBookings = bookings.filter(isMeetingRoomBooking);
        return {seatBookings, meetingRoomBookings}
    }, [bookings])
}