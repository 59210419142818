import React, {useEffect, useMemo, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slider from "@mui/material/Slider";
import {MeetingRoomBookingListItem} from "../../types/MeetingRoomBookingListItemType";
import {getMeetingRoomType, MeetingRoomType} from "../../Utils/Enums";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {
    StyledDivCapacityPanel,
    StyledDivLeftPanel,
    StyledDivMatrixContainer,
    StyledDivRightPanel
} from "../../styles/MeetingRoomBookingMatrixStyles";
import {User} from "../../services/UserClient";
import {BookingDialog, BookingDialogProps} from "../BookingDialog/BookingDialog";
import {Building, MeetingRoom, MeetingRoomEquipment, Room} from "../../API";
import dayjs from "dayjs";
import MeetingRoomBookingMatrixRoomNamesHeaderComponent
    from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixRoomNamesHeaderComponent";
import MeetingRoomBookingMatrixRoomNamesComponent
    from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixRoomNamesComponent";
import MeetingRoomBookingMatrixTimeHeaderComponent
    from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixTimeHeaderComponent";
import MeetingRoomBookingMatrixGridContainerComponent
    from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixGridContainerComponent";
import {meetingRoomIconFromType, meetingRoomTypeFromString} from "../../Utils/MeetingRoomBookingManagerComponentHelper";
import {RoomProps} from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixRoomNameComponent";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {ManagePermission, MeetingRoomWithLevelP} from "../../types/PermissionHandling";
import {compareMeetingRoomsWithLevel, compareTimeSlotData} from "../../Utils/Comparison";
import {toMinutesAfterMidnight} from "../../Utils/Conversion";
import MeetingRoomBookingMatrixRoomCapacitiesHeaderComponent
    from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixRoomCapacitiesHeaderComponent";
import MeetingRoomBookingMatrixRoomCapacitiesComponent
    from "./MeetingRoomBookingMatrixComponentComponents/MeetingRoomBookingMatrixRoomCapacitiesComponent";
import {comparatorAlphanumericValues} from "../../Utils/Helpers";

export interface Props {
    bookingListItems: MeetingRoomBookingListItem[],
    meetingRoomsWithLevel: MeetingRoomWithLevelP[],
    buildingEquipment: Map<string, MeetingRoomEquipment>,
    rooms: Room[],
    building: Building | undefined,
    setIdOfHighlightedBooking: (id: string | null) => void,
    editBooking: (item: MeetingRoomBookingListItem) => void,
    currentUser: User,
    date: Date,
    refetchBookings: () => void
}

export interface TimeSlotData {
    timeStartInMinutes: number
    timeEndInMinutes: number
    onHoverStart: () => void
    onHoverEnd: () => void
    onClick: () => void
    bookingItem: MeetingRoomBookingListItem
}

interface TimeSlotRowProps {
    startHour: number
    endHour: number
    timeSlotData: TimeSlotData[]
    startBooking: (a: number, b: number, meetingRoom: MeetingRoom) => void;
    hoursDisplayed: number
    meetingRoom: MeetingRoom;
}

interface RoomListProps {
    roomProps: RoomProps[]
    buildingEquipment: Map<string, MeetingRoomEquipment>
}

export type MeetingRoomData = {
    meetingRoomId: string
    type: MeetingRoomType
    neighborhoodId: string | null
    level: number | null
    equipmentIds: string[] | null | undefined,
    name: string
} & ManagePermission

export interface MeetingRoomDataWithCapacity extends MeetingRoomData {
    meetingRoomCapacity: number | null
}


interface GridProps {
    rowProps: TimeSlotRowProps[]
    hoursDisplayed: number
    numberOfColumns: number
}

interface TimeHeaderProps {
    startTimeHours: number
    hoursDisplayed: number
}

const DEFAULT_HOURS_SHOWN: number = 14;
const DEFAULT_START_HOUR = 7;
const HOURS_PER_DAY = 24
const DEFAULT_TIME_OF_TIME_SLOT: string = "00:00"; //not the right time format

function MeetingRoomBookingMatrixComponent(props: Props) {
    const {
        bookingListItems,
        meetingRoomsWithLevel,
        rooms,
        setIdOfHighlightedBooking,
        editBooking,
        buildingEquipment,
        building,
    } = props
    const [showBookingDialog, setShowBookingDialog] = useState(false);
    const [bookingDialogData, setBookingDialogData] = useState<BookingDialogProps | null>(null);
    const {isMobile} = useDeviceMediaType();
    const [startAtHours, setStartAtHours] = useState<number>(DEFAULT_START_HOUR);
    const [hoursShown, setHoursShown] = useState<number>(DEFAULT_HOURS_SHOWN);

    useEffect(() => setStartAtHours(DEFAULT_START_HOUR), [bookingListItems]);

    useEffect(() => {
        setStartAtHours(isMobile ? 0 : DEFAULT_START_HOUR);
        setHoursShown(isMobile ? 24 : DEFAULT_HOURS_SHOWN);
    }, [isMobile])

    const [roomListProps, timeHeaderProps, gridProps] = useMemo(() => {
        const meetingRoomSortingData: MeetingRoomDataWithCapacity[] = [];
        const filteredMeetingRoomsWithLevel = meetingRoomsWithLevel.filter(meetingRoom => meetingRoom.isBookable)
        const meetingRoomIdToBookings = new Map<string, TimeSlotData[]>();

        [...filteredMeetingRoomsWithLevel].sort(compareMeetingRoomsWithLevel).forEach(mr => {
            meetingRoomIdToBookings.set(mr.meetingRoomId, []);
            meetingRoomSortingData.push(
                {
                    meetingRoomId: mr.meetingRoomId,
                    type: meetingRoomTypeFromString(mr.type),
                    neighborhoodId: mr.neighborhoodId,
                    level: mr.level,
                    name: mr.name,
                    meetingRoomCapacity: mr.capacity ?? null,
                    equipmentIds: mr.equipmentIds,
                    managingAllowed: mr.managingAllowed
                }
            )
        })

        for (const item of bookingListItems) {
            const id = item.meetingRoomId;
            const bookingsData = meetingRoomIdToBookings.get(id) ?? [];
            bookingsData.push(toTimeSlotData(item, setIdOfHighlightedBooking, editBooking));
            meetingRoomIdToBookings.set(id, bookingsData);
        }

        meetingRoomSortingData.sort(compareMeetingRoomsWithLevel);

        for (const meetingRoomData of meetingRoomSortingData) {
            const id = meetingRoomData.meetingRoomId;
            const tsdList = meetingRoomIdToBookings.get(id) ?? []
            tsdList.sort(compareTimeSlotData);
            meetingRoomIdToBookings.set(id, tsdList);
        }

        const sortedBookingsPerRoom = [...filteredMeetingRoomsWithLevel]
            .sort(compareMeetingRoomsWithLevel)
            .map((mr) => {
                    const bookings = meetingRoomIdToBookings.get(mr.meetingRoomId) ?? [];
                    return ({
                        //filter because meetingRoomId is not unique
                        bookings: bookings.filter(tsd => tsd.bookingItem.roomId === mr.roomId),
                        meetingRoom: mr
                    })
                }
            );

        const gridProps: GridProps = {
            rowProps: sortedBookingsPerRoom.map(({bookings, meetingRoom}) => {
                let rowProps: TimeSlotRowProps;
                rowProps = {
                    timeSlotData: bookings,
                    startHour: startAtHours,
                    endHour: startAtHours + hoursShown,
                    hoursDisplayed: hoursShown,
                    startBooking: startBooking,
                    meetingRoom: meetingRoom
                }
                return rowProps;
            }),
            hoursDisplayed: hoursShown,
            numberOfColumns: filteredMeetingRoomsWithLevel.length
        };

        const timeHeaderProps: TimeHeaderProps = {
            startTimeHours: startAtHours,
            hoursDisplayed: hoursShown
        }

        const roomListProps: RoomListProps = {
            roomProps: meetingRoomSortingData.map(data => {
                const roomNameProps: RoomProps = {
                    data: data,
                    typeIcon: meetingRoomIconFromType(data.type),
                    buildingEquipment
                }
                return roomNameProps;
            }),
            buildingEquipment: buildingEquipment,
        }

        return [roomListProps, timeHeaderProps, gridProps];
    }, [props.date, bookingListItems, meetingRoomsWithLevel, startAtHours]);

    function getBookingClickHandler(editFunction: () => void, bookingItem: MeetingRoomBookingListItem): () => void {
        if (!bookingItem.managingAllowed) {
            return () => {
            };
        }
        return editFunction;
    }

    function toTimeSlotData(bookingItem: MeetingRoomBookingListItem, setHighlightedBookingId: (id: string | null) => void, editBooking: (item: MeetingRoomBookingListItem) => void): TimeSlotData {
        return {
            timeStartInMinutes: toMinutesAfterMidnight(bookingItem.timeBegin ?? DEFAULT_TIME_OF_TIME_SLOT), //TODO filter out if no time set
            timeEndInMinutes: toMinutesAfterMidnight(bookingItem.timeEnd ?? DEFAULT_TIME_OF_TIME_SLOT),     //TODO filter out if no time set
            onHoverStart: () => setHighlightedBookingId(bookingItem.bookingId),
            onHoverEnd: () => setHighlightedBookingId(null),
            onClick: getBookingClickHandler(() => editBooking(bookingItem), bookingItem),
            bookingItem: bookingItem
        };
    }

    function startBooking(startTime: number, endTime: number, meetingRoom: MeetingRoom) {

        const correspondingRoom = rooms.find(room => room.roomId === meetingRoom.roomId)!;

        setBookingDialogData({
            bookingType: "meetingRoom",
            handleClose: () => {
                setShowBookingDialog(false);
                props.refetchBookings();
            },
            dateToSelectInitially: props.date,
            bookerName: props.currentUser.name,
            bookerGivenName: props.currentUser.givenName,
            bookerFamilyName: props.currentUser.familyName,
            seatId: meetingRoom.meetingRoomId,
            room: correspondingRoom,
            building: building,
            selectedSeatIsBookedOnSelectedDay: true,
            bookingList: bookingListItems,
            bookingStartTime: minutesToDayjs(startTime),
            bookingEndTime: minutesToDayjs(endTime),
            roomCap: meetingRoom?.capacity ?? 0,
            meetRoomType: getMeetingRoomType(meetingRoom?.type ?? ""),
            seatConfNames: meetingRoom.seatingConfNames ?? [],
            seatConfDaysInAdvance: meetingRoom.seatingConfDays ?? 0
        });

        setShowBookingDialog(true);

        function minutesToDayjs(minutes: number) {
            return dayjs().startOf('day').add(minutes, 'minute');
        }
    }

    const handleSliderChange = (event: Event, value: number | number[]) => {
        event.preventDefault();
        const newValue = Array.isArray(value) ? value[0] : value;
        setStartAtHours(newValue);
    }

    const sliderProps = {
        min: 0,
        max: HOURS_PER_DAY - hoursShown,
        step: 1,
        onChange: handleSliderChange,
        value: startAtHours
    }
    const leftBtnProps = {
        onClick: () => setStartAtHours(prev => prev - 1),
        disabled: startAtHours === 0
    }
    const rightBtnProps = {
        onClick: () => setStartAtHours(prev => prev + 1),
        disabled: (startAtHours + hoursShown) === HOURS_PER_DAY
    }
    const dialogProps = {
        PaperProps: {style: {maxWidth: "none"}},
        open: showBookingDialog
    }
    const isShowDialog = showBookingDialog && bookingDialogData;

    const sortedRoomList = roomListProps.roomProps
        .sort((a, b) => comparatorAlphanumericValues(a.data.name, b.data.name));

    return <>
        {isShowDialog && <Dialog {...dialogProps}><BookingDialog {...bookingDialogData} /></Dialog>}
        <StyledDivMatrixContainer data-testid={"meeting-room-matrix"}>
            <StyledDivLeftPanel>
                <MeetingRoomBookingMatrixRoomNamesHeaderComponent/>
                <MeetingRoomBookingMatrixRoomNamesComponent
                    sortedRooms={sortedRoomList}
                    buildingEquipment={roomListProps.buildingEquipment}/>
            </StyledDivLeftPanel>
            <StyledDivCapacityPanel>
                <MeetingRoomBookingMatrixRoomCapacitiesHeaderComponent/>
                <MeetingRoomBookingMatrixRoomCapacitiesComponent
                    sortedRoomCapacities={sortedRoomList.map(rp => rp.data.meetingRoomCapacity)}/>
            </StyledDivCapacityPanel>
            <StyledDivRightPanel>
                <MeetingRoomBookingMatrixTimeHeaderComponent {...timeHeaderProps}/>
                <MeetingRoomBookingMatrixGridContainerComponent {...gridProps}/>
            </StyledDivRightPanel>
        </StyledDivMatrixContainer>
        {!isMobile && <>
            <DialogActions>
                <Button {...leftBtnProps} data-testid={"meeting-room-matrix-button-slider-left"}><ChevronLeft/></Button>
                <Slider {...sliderProps} data-testid={"meeting-room-matrix-slider"}/>
                <Button {...rightBtnProps}
                        data-testid={"meeting-room-matrix-button-slider-right"}><ChevronRight/></Button>
            </DialogActions>
        </>
        }
    </>
}

export default MeetingRoomBookingMatrixComponent
