import {Booking, MeetingRoomBooking} from "../../../API";
import dayjs from "dayjs";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Stack, styled, Typography} from "@mui/material";
import {CustomIconButton} from "../BookingsPopup/CustomIconButton";
import Box from "@mui/material/Box";

interface Props {
    deletableBookings: (MeetingRoomBooking)[]
    handleDeleteBooking: (booking: MeetingRoomBooking) => void
}

const StyledDeleteCustomIconButton = styled(CustomIconButton)({
    width: "30px"
});

// Only used for meeting room bookings

const DeleteBookingButtons: React.FC<Props> = (props) => {
    const {
        deletableBookings,
        handleDeleteBooking
    } = props
    const [sortedBookings, setSortedBookings] = useState<(MeetingRoomBooking)[]>([])
    const {t} = useTranslation();

    useEffect(function sortBookings() {
        const tempBookingList = [...deletableBookings]
        tempBookingList.sort((a, b) => {
            return (a.timeBegin || "") < (b.timeBegin || "") ? -1 : 1
        })
        setSortedBookings(tempBookingList)
    }, [deletableBookings])

    function formatDate(date: string) {
        return dayjs(date).format("HH:mm")
    }

    function getButtonText(booking: Booking | MeetingRoomBooking) {
        if (booking.__typename === "MeetingRoomBooking") return getMeetingText(booking)
        else return getSeatText(booking)

        function getSeatText(booking: Booking) {
            if (booking.timeBegin) {
                return formatDate((booking.timeBegin || "")) + "\u202F\u2013\u202F" + (formatDate((booking.timeEnd ?? "")));
            } else {
                return t("button_delete_booking");
            }
        }

        function getMeetingText(booking: MeetingRoomBooking) {
            return booking.meetingName + " (" + formatDate((booking.timeBegin ?? "")) + "\u202F\u2013\u202F" + (formatDate((booking.timeEnd ?? ""))) + " )";
        }
    }

    return (
        <>
            {
                sortedBookings.map((booking) => {
                    return (
                        <Stack
                            key={booking.bookingId}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            sx={{
                                padding: "4px 0px 4px 4px",
                                boxSizing: "border-box",
                            }}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: "bold",
                                    marginRight: "10px",
                                    fontSize: "13px"
                                }}
                            >
                                {getButtonText(booking)}
                            </Typography>
                            <Box>
                                <StyledDeleteCustomIconButton icon={<DeleteIcon/>}
                                                              data-testid={"already-booked-delete-booking"}
                                                              onClick={() => handleDeleteBooking(booking)}/>
                            </Box>
                        </Stack>
                    );
                })
            }
        </>
    );

}

export default DeleteBookingButtons