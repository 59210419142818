import React from 'react';
import {SeatInterface} from '../SeatComponent'

interface Props {
    seatComponent: SeatInterface
    isHovered: boolean
}

const TableNumber: React.FC<Props> = (props) => {

    const {seatComponent, isHovered} = props

    return (
        <text
            visibility={isHovered ? "visible" : "hidden"}
            id={`table-text-${seatComponent.seatID}`}
            x={seatComponent.tableTextPosition.x}
            y={seatComponent.tableTextPosition.y}
            className="tableNumber"
            transform={seatComponent.tableTextPosition.transform}
        >
            <tspan
                x={seatComponent.tableTextPosition.x}
                y={seatComponent.tableTextPosition.y}
                id={"tspan904" + seatComponent.seatID}>{seatComponent.seatID.toString()}

            </tspan>
        </text>
    );
}

export default TableNumber;